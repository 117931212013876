import React from "react";
import styled from "styled-components";
import { Checkbox } from "../shared";
import { formatToGBP } from "../../utils/currency";

const GiftAidItContainer = styled.div``;
const GiftAidArea = styled.div`
  width: 70.9rem;
  height: 38.9rem;
  text-align: center;
  padding: 5.4rem 3.1rem 3.6rem;
  border: 1px solid ${({ theme }) => theme.gray2};
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 1.9rem;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 100%;
  }

  .title {
    font-size: 2.4rem;
    line-height: 140.5%;
    margin-bottom: 3.6rem;
    span {
      color: ${({ theme }) => theme.orange};
    }
  }

  .notification {
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
  }

  .agreement {
    width: 100%;
    display: flex;
    background: ${({ theme }) => theme.white};
    justify-content: center;
    align-items: center;
    border: 1px solid ${({ theme }) => theme.gray2};
    border-radius: 8px;
    height: 5.8rem;
    font-size: 1.6rem;
    line-height: 150%;
    margin-top: 2rem;

    @media screen and (max-width: 992px) {
      padding: 0 1rem;
    }
  }

  .warning {
    font-size: 1rem;
    line-height: 150.5%;
    color: ${({ theme }) => theme.gray};
    margin-top: 2.1rem;
  }
`;

const GiftAidIt: React.FC<{
  amount: number;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
}> = ({ amount, handleBlur, handleChange }) => {
  const giftAidWorth = amount + 0.25 * amount;

  return (
    <GiftAidItContainer>
      <img src="/images/giftaid.png" alt="giftaid" />

      <GiftAidArea>
        <h2 className="title">
          Make your <span>{formatToGBP(amount, 0)}</span> worth{" "}
          <span>{formatToGBP(giftAidWorth)}</span> at no extra cost to you!
        </h2>

        <p className="notification">
          If you are a UK taxpayer, the value of your donation can be increased
          by 25% under the Gift Aid scheme without costing you a penny more.
        </p>

        <div className="agreement">
          <Checkbox
            onChange={handleChange}
            onBlur={handleBlur}
            name="giftAid"
            label={
              <span>
                Yes, I want the ASA UK to claim Gift Aid on my donations*
              </span>
            }
          />
        </div>

        <p className="warning">
          *This includes any donations you have made in the past four years and
          all future donations. To qualify for Gift Aid, you must be a UK
          taxpayer and understand that if you pay less Income Tax and/or Capital
          Gains Tax than the amount of Gift Aid claimed on all your donations in
          that tax year it is your responsibility to pay any difference. Please
          notify ASA UK if you want to cancel this declaration, change your name
          or home address, or no longer pay sufficient tax on your income and/or
          capital gains.
        </p>
      </GiftAidArea>
    </GiftAidItContainer>
  );
};

export default GiftAidIt;
