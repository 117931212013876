import React, { useEffect } from "react";
import { useSelector, connect } from "react-redux";
import format from "date-fns/format";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PageContainer, Spacer, CenterBox, PageLoader } from "../../shared";
import { H1, HR } from "../styles";
import { GoBack } from "../styles";
import { ReactComponent as BackIcon } from "../../../svgs/caret-left.svg";
import { getSingleNews } from "../../../redux/actions/news";
import { PageTitle } from "../../shared/PageTitle";
import useLoading from "../../../hooks/useLoading";

const NewsWrapper = styled.div`
  display: flex;
  margin-top: 4rem;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
  }
`;

const NewsContent = styled.div`
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column-reverse;
  }

  .socialIconBg {
    margin-right: 6.9rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1000px) {
      margin-right: 0;
      flex-direction: row;
      margin-top: 3rem;
    }

    img {
      max-width: 4rem;
      margin-bottom: 2.4rem;

      @media screen and (max-width: 1000px) {
        margin-right: 2rem;
      }
    }
  }

  .news-content {
    margin: auto;

    a {
      color: ${({ theme }) => theme.orange};
      text-decoration: none;
    }
  }
`;

const NewsInfo = styled.div`
  flex: 1;
  font-size: 1.6rem;
  line-height: 150%;

  .subtitleDate {
    margin-bottom: 2rem;

    p {
      font-size: 1.4rem;
      line-height: normal;
    }

    a {
      color: ${({ theme }) => theme.orange};
      text-decoration: none;
    }
  }

  figure {
    width: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  p {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.black2};
    line-height: 150%;
  }
`;

const BackToNews = styled(GoBack)`
  width: 100%;
  height: 12.2rem;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.offWhite2};
`;

const SingleNews: React.FC<{
  match: any;
  getSingleNews: Function;
}> = ({
  match: {
    params: { newsID },
  },
  getSingleNews,
}) => {
  const [isLoading, load] = useLoading();

  useEffect(() => {
    load(getSingleNews(newsID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsID]);

  const news = useSelector(({ singleNews }: any) => singleNews.data);

  return (
    <>
      {isLoading ? (
        <PageLoader />
      ) : (
        news && (
          <PageContainer>
            <PageTitle
              title={news.title}
              description={news.title.substr(0, 50) + "..."}
            />
            <Spacer height={8.9} />

            <H1>{news.title}</H1>

            <HR />

            <NewsWrapper>
              <NewsInfo>
                <div className="subtitleDate">
                  <p>
                    Posted on {format(news.post_date, "D MMMM, YYYY")} in{" "}
                    <Link to={`/dashboard/news/category/${news.category.id}`}>
                      {news.category.name}
                    </Link>
                  </p>
                </div>
                <figure>
                  <img src={news.featured_img_src} alt={news.title} />
                </figure>

                <Spacer height={3.8} />
                <NewsContent>
                  <div className="socialIconBg">
                    <a
                      href={
                        "https://www.facebook.com/sharer/sharer.php?u=" +
                        window.location.href
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/images/facebook.png" alt="share-on-facebook" />
                    </a>
                    <a
                      href={
                        "http://twitter.com/share?text=" + window.location.href
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src="/images/twitter.png" alt="share-on-facebook" />
                    </a>
                  </div>
                  <div className="news-content">
                    <p
                      dangerouslySetInnerHTML={{
                        __html: news.content
                          .split("&nbsp;")
                          .join("<br /><br />"),
                      }}
                    />
                  </div>
                </NewsContent>
              </NewsInfo>
            </NewsWrapper>
          </PageContainer>
        )
      )}

      <Spacer height={13.5} />

      <BackToNews>
        <CenterBox>
          <Link to="/dashboard/news">
            <BackIcon />
            Go Back
          </Link>
        </CenterBox>
      </BackToNews>
    </>
  );
};

export default connect(
  null,
  {
    getSingleNews,
  }
)(SingleNews);
