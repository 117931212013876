import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { NewsGrid, EventCard, FirstCardContent } from "./styles";
import ViewAll from "./ViewAll";
import { ReactComponent as RightIcon } from "../../svgs/caret-right.svg";
import { GhostButton, EventContent } from "../shared";
import { getEvents } from "../../redux/actions/event";

const reqParams = {
  date: "",
  perPage: 2,
  page: 1,
  filter: ""
};

interface Props extends RouteComponentProps {
  getEvents: Function;
}

const Events: React.FC<Props> = ({ getEvents, history }) => {
  const events = useSelector(({ events }: any) => events.data);

  useEffect(() => {
    getEvents(reqParams);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsGrid>
      <EventCard>
        <FirstCardContent className="eventsContent">
          <p className="title">Events</p>

          <ViewAll to="/dashboard/events" className="events">
            View all <RightIcon />
          </ViewAll>
        </FirstCardContent>
      </EventCard>
      {events &&
        events.data.slice(0, 2).map((event: any) => (
          <EventCard
            key={event.id}
            onClick={() => history.push(`/dashboard/events/${event.slug}`)}
          >
            <EventContent
              featuredImage={event.featured_image}
              className="eventsContent"
            >
              <div className="header">
                <span>When- {event.events_date}</span>
                <span>{event.category && event.category.name}</span>
              </div>

              <Link
                to={`/dashboard/events/${event.slug}`}
                className="title"
                title={event.post_title}
              >
                {event.post_title.length < 35
                  ? event.post_title
                  : event.post_title.substr(0, 35) + "..."}
              </Link>
              <div>
                <GhostButton as={Link} to={`/dashboard/events/${event.slug}`}>
                  Read more
                </GhostButton>
              </div>
            </EventContent>
          </EventCard>
        ))}
    </NewsGrid>
  );
};

export default connect(null, {
  getEvents
})(withRouter(Events));
