import React from "react";
import { Form, H1 } from "./styles";
import {
  Separator,
  Steps,
  Spacer,
  CenterBox,
  ScrollToTopOnMount
} from "../shared";
import styled from "styled-components";
import { ReactComponent as Gift } from "../../svgs/gift.svg";

const H2 = styled.h2`
  color: ${({ theme }) => theme.orange};
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 118.5%;
  margin-bottom: 3.8rem;
`;

const Paragraph = styled.p`
  font-size: 1.6rem;
  margin: 0 auto;
  line-height: 150%;
`;

const Step3: React.FC<{
  state: { [key: string]: string | boolean };
  plan: string;
}> = ({ state, plan }) => {
  return (
    <>
      <ScrollToTopOnMount />
      <Form>
        <H1>Thank you for your gift purchase</H1>

        <CenterBox>
          <Separator />
        </CenterBox>

        <Steps step={3} maxSteps={3} />

        <H2>
          Thank you for purchasing a gift
          <br />
          membership for the ASA UK
        </H2>

        <Gift className="gift" />

        <Spacer height={3.8} />

        <Paragraph>
          Your payment has been processed and you will receive an email within
          the next 5 working days which you can forward on to the recipient to
          let them know you have given them ASA UK membership as a gift. You can
          choose the date (i.e. a birthday) when you would like to send this to
          the recipient.
        </Paragraph>

        <Spacer height={3} />

        <Paragraph>
          Then, the recipient simply has to email us, and we will give them
          details of how to set up their online account on the ASA UK website.
          Here they can update their contact details, change their newsletter
          subscriptions and access exclusive discounts.
        </Paragraph>

        <Spacer height={3} />
      </Form>
    </>
  );
};

export default Step3;
