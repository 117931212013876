import React from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

const StyledLink = styled(Link)`
  color: inherit;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 1.9rem;
  display: inline-block;
  text-decoration: none;

  &:hover {
    ${({ theme }) => css`
      color: ${theme.orange};

      path {
        fill: ${theme.orange};
        stroke: ${theme.orange};
      }
    `}
  }

  &.events:hover {
    ${({ theme }) => css`
      color: ${theme.deepGreen};

      path {
        fill: ${theme.deepGreen};
        stroke: ${theme.deepGreen};
      }
    `}
  }

  svg {
    height: 1.5rem;
    width: 1.7rem;
    margin-top: -4px;
    margin-left: 7px;
    vertical-align: middle;

    path {
      fill: ${({ theme }) => theme.white};
      stroke: ${({ theme }) => theme.white};
    }
  }
`;

const ViewAll: React.FC<{ to: string; className?: string}> = ({
  to,
  children,
  className,
}) => (
  <StyledLink to={to} className={className} >
    {children}
  </StyledLink>
);

export default ViewAll;
