import React, { useContext } from "react";
import styled, { css } from "styled-components";
import Context from "./Context";

interface Props {
  active: boolean;
  theme: any;
}

const TabButton = styled.button<Props>`
  font-size: 2.4rem;
  line-height: 6rem;
  letter-spacing: -0.035em;
  min-width: 39.3rem;
  cursor: pointer;
  outline: transparent;
  height: 7rem;
  border: none;
  font-weight: 500;

  @media screen and (max-width: 992px) {
    height: auto;
  }

  &:first-child {
    border-top-left-radius: 6.2rem;
    border-bottom-left-radius: 6.2rem;

    @media screen and (max-width: 992px) {
      margin-bottom: 1.5rem;
      min-width: auto;
      border-radius: 6.2rem;
    }
  }

  &:last-child {
    border-top-right-radius: 6.2rem;
    border-bottom-right-radius: 6.2rem;
    margin-left: -6.3rem;

    @media screen and (max-width: 992px) {
      margin-left: 0;
      border-radius: 6.2rem;
      min-width: 100%;
    }
  }

  ${({ active, theme }) =>
    active
      ? css`
          background: ${theme.orange};
          border-radius: 6.2rem;
          z-index: 10;
          color: ${theme.white};
        `
      : css`
          background: ${theme.offWhite};
          z-index: 5;
        `}
`;

const TabsBox = styled.div`
  height: 7rem;
  display: inline-flex;
  width: 72.3rem;
  border-radius: 6.2rem;

  @media screen and (max-width: 992px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5.2rem;
  margin-bottom: 7rem;

  @media screen and (max-width: 992px) {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`;

const Tabs: React.FC = () => {
  const state = useContext(Context);

  return (
    <TabsContainer>
      <TabsBox>
        <TabButton
          type="button"
          onClick={() => {
            state.setState({
              ...state,
              donationType: "one-off"
            });
          }}
          active={state.donationType === "one-off"}
        >
          One off
        </TabButton>
        <TabButton
          type="button"
          onClick={() => {
            state.setState({
              ...state,
              donationType: "monthly"
            });
          }}
          active={state.donationType === "monthly"}
        >
          Monthly
        </TabButton>
      </TabsBox>
    </TabsContainer>
  );
};

export default Tabs;
