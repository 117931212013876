import React, { useState } from "react";
import { useSelector, connect } from "react-redux";
import styled, { css } from "styled-components";
import {
  Link,
  NavLink as NavigationLink,
  withRouter,
  RouteComponentProps
} from "react-router-dom";
import PageContainer from "../PageContainer";
import Button from "../Button";
import logOut from "../../../redux/actions/logOut";
import { Burger } from "./Burger";

const Nav = styled.nav<{ showMobileNav: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 0;
  z-index: 9999;

  @media screen and (max-width: 992px) {
    padding: 1.2rem 0;
  }

  .logo {
    z-index: 1;
  }

  .close {
    path {
      fill: ${({ theme }) => theme.white};
    }
  }

  ${({ showMobileNav }) =>
    showMobileNav &&
    css`
      position: sticky;
      top: 0;
    `};

  figure {
    z-index: 9999;
  }

  .action-button {
    display: inline-block;
    margin-left: 2.2rem;

    @media screen and (max-width: 992px) {
      margin-right: 2.2rem;

      .navButton {
        display: flex;
        justify-content: center;
        width: 100%;
        font-size: 2rem;
      }
    }
  }

  li:not(.action-button) {
    display: inline-block;
    margin-left: 3.2rem;

    a:hover {
      border-bottom: 2px solid ${({ theme }) => theme.black2};
    }
  }

  .hidden {
    display: none;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: transparent;
  }

  @media screen and (max-width: 967px) {
    .visible {
      position: fixed;
      margin-top: -13px;
      opacity: 0;
      left: -100%;
      transition: all 0.2s ease-out;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.8);

      ${({ showMobileNav, theme }) =>
        showMobileNav &&
        css`
          display: unset;
          display: flex;
          flex-direction: column;
          opacity: 1;
          left: 0;
          transition: left 0.2s ease-in;
          padding: 180px 20px;

          li() {
            margin-left: 0;
            margin-bottom: 20px;
            padding-left: 40px;
          }

          a {
            color: ${theme.white};
            font-size: 20px;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
            display: flex;
            align-items: center;
            letter-spacing: 0.035em;
            text-transform: uppercase;
            font-weight: 400;
          }
        `}
    }

    .hidden {
      display: unset;
      z-index: 9999;
    }
  }
`;

const NavLink = styled(NavigationLink)`
  color: ${({ theme }) => theme.black2};
  font-size: 1.4rem;
  text-decoration: none;
  padding: 0.3rem 0;

  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.black2};
    font-weight: 500;
  }
`;

const Div = styled.div`
  background: ${({ theme }) => theme.gray5};
`;

interface Props extends RouteComponentProps<any> {
  logOut: Function;
}

const PrimaryNav: React.FC<Props> = ({ logOut, history, match }) => {
  const isAuthed = useSelector((state: any) => state.user.data);
  const [mobileNavState, setMobileNavState] = useState(false);

  const register = (
    <li className="action-button">
      <Button as="a" href="http://asauk.net/membership" className="navButton">
        BECOME A MEMBER
      </Button>
    </li>
  );

  const login = (
    <li className="action-button">
      <Button secondary as={Link} to="/login" className="navButton">
        SIGN IN
      </Button>
    </li>
  );

  const donate = (
    <li className="action-button">
      <Button secondary as={Link} to="/donate" className="navButton">
        DONATE
      </Button>
    </li>
  );

  const donate2 = (
    <li className="action-button">
      <Button as={Link} to="/donate" className="navButton">
        DONATE
      </Button>
    </li>
  );

  return (
    <Div>
      <PageContainer>
        <Nav showMobileNav={mobileNavState}>
          <a href="http://asauk.net" className="logo">
            <figure>
              <img src="/images/logo.png" height="56" alt="ASAUK" />
            </figure>
          </a>

          <>
            <button
              className="hidden"
              onClick={() => {
                setMobileNavState(!mobileNavState);
              }}
            >
              <Burger
                mobileNavState={mobileNavState}
                setMobileNavState={setMobileNavState}
              />
            </button>

            {isAuthed ? (
              <ul className="visible">
                <li>
                  <NavLink
                    to="/dashboard"
                    exact
                    onClick={() => {
                      setMobileNavState(false);
                    }}
                  >
                    HOME
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/african-affairs"
                    exact
                    onClick={() => {
                      setMobileNavState(false);
                    }}
                  >
                    AFRICAN AFFAIRS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/account"
                    onClick={() => {
                      setMobileNavState(false);
                    }}
                  >
                    ACCOUNT
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/profile"
                    onClick={() => {
                      setMobileNavState(false);
                    }}
                  >
                    PROFILE
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/dashboard/faqs"
                    onClick={() => {
                      setMobileNavState(false);
                    }}
                  >
                    FAQS
                  </NavLink>
                </li>
                <li
                  className="action-button"
                  onClick={() => {
                    setMobileNavState(false);
                  }}
                >
                  <Button
                    secondary
                    onClick={() => logOut(history.push)}
                    className="navButton"
                  >
                    SIGN OUT
                  </Button>
                </li>
              </ul>
            ) : (
              <ul className="visible">
                {(match.path.endsWith("login") || match.path.endsWith("/")) && (
                  <>
                    {register}
                    {donate}
                  </>
                )}

                {match.path.endsWith("donate") && (
                  <>
                    {register}
                    {login}
                  </>
                )}

                {match.path.endsWith("gifting") && (
                  <>
                    {donate2}
                    {login}
                  </>
                )}

                {match.path.endsWith("sign-up") && (
                  <>
                    {donate2}
                    {login}
                  </>
                )}

                {match.path.endsWith("reset-password") && (
                  <>
                    {register}
                    {donate}
                  </>
                )}
              </ul>
            )}
          </>
        </Nav>
      </PageContainer>
    </Div>
  );
};

export default connect(null, { logOut })(withRouter(PrimaryNav));
