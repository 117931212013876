import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Spacer, Steps } from "../../shared";
import {
  Container,
  H1,
  P,
  StepsWrapper,
  Plans,
  Plan,
  BtnCont,
  CTABtn
} from "./styles";
import { prices, plans } from "./metaData";
import { PageTitle } from "../../shared/PageTitle";

const UpgradeSubscription: React.FC = () => {
  const subscription = useSelector((state: any) => state.subscription);
  const currentPlan = subscription.data.plan;
  const higherPlans = plans.filter(
    newPlan => prices[newPlan].price > prices[currentPlan].price
  );

  return (
    <>
      <PageTitle
        title="Upgrade Plan"
        description="Upgrade and enjoy more from ASA UK"
      />
      <Container>
        <Spacer height={11} />

        <H1>Choose a plan</H1>

        <Spacer height={1.3} />

        <P>Upgrade and enjoy more from ASA UK</P>

        <Spacer height={5.8} />

        <StepsWrapper>
          <Steps maxSteps={2} step={1} />
        </StepsWrapper>

        <Plans>
          {higherPlans.map(plan => (
            <Plan key={plan}>
              <p className="title">{plan}</p>

              <Spacer height={2} />
              <p className="price">£{prices[plan].price}</p>

              <p className="description">{prices[plan].desc}</p>

              <Spacer height={2.5} />

              <BtnCont>
                <CTABtn
                  as={Link}
                  to={`/dashboard/account/upgrade-plan/pay?plan=${plan}`}
                >
                  Select {plan}
                </CTABtn>
              </BtnCont>
            </Plan>
          ))}
        </Plans>

        <Spacer height={11.4} />
      </Container>
    </>
  );
};

export default UpgradeSubscription;
