import React from "react";
import styled from "styled-components";
import format from "date-fns/format";
import { Formik } from "formik";
import { connect } from "react-redux";
import { ProfileForm } from "./styles";
import {
  FormGroup,
  InputWrapper,
  Input,
  Spacer,
  Select,
  countryList
} from "../shared";
import Address from "./Address";
import SaveInfo from "./SaveInfo";
import { updateProfile } from "../../redux/actions/user";
import { personalInfo } from "./validationSchemas";
import useLoading from "../../hooks/useLoading";

const PersonalInformation: React.FC<{ user: any; updateProfile: Function }> = ({
  user,
  updateProfile
}) => {
  const [isLoading, load] = useLoading();

  const day = user.date_of_birth ? format(user.date_of_birth, "DD") : "";
  const month = day && format(user.date_of_birth, "MM");
  const year = month && format(user.date_of_birth, "YYYY");

  const handleSubmit = (userData: { [key: string]: string }) => {
    const { day, month, year, ...payload } = userData;
    const dateOfBirth = `${year}-${month}-${day}`;
    payload.dateOfBirth = dateOfBirth;

    load(updateProfile(payload));
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: user.title,
          firstName: user.first_name,
          lastName: user.last_name,
          nationality: user.nationality,
          phone: user.phone,
          genderIdentity: user.gender_identity,
          day,
          month,
          year
        }}
        onSubmit={handleSubmit}
        validationSchema={personalInfo}
      >
        {({
          handleBlur,
          handleSubmit,
          values,
          handleChange,
          dirty,
          touched,
          errors,
          handleReset
        }) => (
          <ProfileForm
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
              handleSubmit(event);
            }}
          >
            <FormGroup>
              <InputWrapper>
                <Select
                  errored={Boolean(touched.title && errors.title)}
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="title"
                >
                  <option value="Sir">Sir</option>
                  <option value="Revd">Revd</option>
                  <option value="Mr">Mr</option>
                  <option value="Ms">Ms</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                  <option value="Miss">Miss</option>
                  <option value="Dame">Dame</option>
                  <option value="Baroness">Baroness</option>
                  <option value="Lord">Lord</option>
                  <option value="Earl">Earl</option>
                  <option value="Rt Hon">Rt Hon</option>
                  <option value="Rt Hon Sir">Rt Hon Sir</option>
                </Select>

                <span className="error">{touched.title && errors.title}</span>
              </InputWrapper>

              <InputWrapper>
                <Select
                  errored={Boolean(
                    touched.genderIdentity && errors.genderIdentity
                  )}
                  value={values.genderIdentity}
                  name="genderIdentity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Gender *</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                  <option value="Non-Binary">Non-Binary</option>
                </Select>

                <span className="error">
                  {touched.genderIdentity && errors.genderIdentity}
                </span>
              </InputWrapper>
            </FormGroup>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.firstName && errors.firstName)}
                  name="firstName"
                  type="text"
                  placeholder="First Name"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <span className="error">
                  {touched.firstName && errors.firstName}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.lastName && errors.lastName)}
                  name="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <span className="error">
                  {touched.lastName && errors.lastName}
                </span>
              </InputWrapper>
            </FormGroup>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.email && errors.email)}
                  name="email"
                  type="email"
                  placeholder="Email"
                  defaultValue={user.email}
                  disabled
                />

                <span className="error">{touched.email && errors.email}</span>
              </InputWrapper>
              <InputWrapper>
                <Select
                  errored={Boolean(touched.nationality && errors.nationality)}
                  value={values.nationality}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="nationality"
                >
                  <option>Nationality</option>

                  {countryList}
                </Select>

                <span className="error">
                  {touched.nationality && errors.nationality}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.phone && errors.phone)}
                  name="phone"
                  type="text"
                  placeholder="Phone"
                  value={values.phone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <span className="error">{touched.phone && errors.phone}</span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <DateOfBirth>
                <Label>Date of Birth *</Label>
                <FormGroup>
                  <DayMonthInput
                    errored={Boolean(touched.day && errors.day)}
                    value={values.day}
                    name="day"
                    placeholder="DD"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <DayMonthInput
                    errored={Boolean(touched.month && errors.month)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.month}
                    name="month"
                    placeholder="MM"
                  />
                  <YearInput
                    errored={Boolean(touched.year && errors.year)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.year}
                    name="year"
                    placeholder="YYYY"
                  />
                </FormGroup>
              </DateOfBirth>
            </FormGroup>

            {dirty && (
              <SaveInfo handleReset={handleReset} isSubmitting={isLoading} />
            )}
          </ProfileForm>
        )}
      </Formik>
      <Spacer height={15} />
      {user && (
        <ProfileForm>
          <Address updateProfile={updateProfile} type="personal" />
        </ProfileForm>
      )}
      <Spacer height={6.7} />
    </>
  );
};

const DateOfBirth = styled.div`
  width: 48%;
  text-align: left;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

const Label = styled.label`
  font-size: 2rem;
  line-height: 1.5rem;
  display: block;
  color: ${({ theme }) => theme.gray};
  padding-left: 3.78rem;
  padding-bottom: 2.3rem;
  margin-top: 4.3rem;
`;

const DayMonthInput = styled(Input)`
  width: 10.9rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-bottom: 1.5rem;
  }
`;

const YearInput = styled(Input)`
  width: 12rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  text-align: center;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export default connect(null, { updateProfile })(PersonalInformation);
