import React from "react";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { useSelector } from "react-redux";
import { TabsContainer, H1, HR } from "./styles";
import { PageContainer, Spacer } from "../shared";
import ProfessionalInformation from "./ProfessionalInformation";
import PersonalInformation from "./PersonalInformation";
import { PageTitle } from "../shared/PageTitle";
import NewsletterInfo from "./NewsletterInfo";

const Profile: React.FC = () => {
  const user = useSelector(({ user }: any) => user.data);

  return (
    <>
      <PageTitle
        title="Membership Details"
        description="Here are your membership details on the ASA UK"
      />
      <TabsContainer>
        <PageContainer>
          <Spacer height={8.9} />
          <H1>Your Profile Details</H1>
          <HR />
        </PageContainer>
        <Tabs>
          <TabList>
            <PageContainer>
              <Spacer height={9.8} />
              <div className="tabs">
                <Tab>Personal Information</Tab>
                <Tab>Professional Information</Tab>
                <Tab>Newsletter Preference</Tab>
              </div>
            </PageContainer>
          </TabList>

          <PageContainer>
            <Spacer height={7.4} />
            <TabPanel>
              <PersonalInformation user={user} />
            </TabPanel>
            <TabPanel>
              <ProfessionalInformation />
            </TabPanel>
            <TabPanel>
              <NewsletterInfo />
            </TabPanel>
          </PageContainer>
        </Tabs>
      </TabsContainer>
    </>
  );
};

export default Profile;
