import styled from "styled-components";

const InputWrapper = styled.div`
  width: 48%;
  position: relative;

  .error {
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.red};
    background: ${({ theme }) => theme.white};
    border: 2px solid ${({ theme }) => theme.white};
    z-index: 100;
  }

  @media screen and (max-width: 774px) {
    width: 100%;

    &:last-child {
      margin-top: 2rem;
    }
  }
`;

export default InputWrapper;
