import { useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import useLoading from "./useLoading";
import { initializeData } from "../components/SignUp/regUtils";

function usePaypal({
  containerId,
  planName,
  planId,
  buttonType,
  action
}: {
  buttonType?: string;
  containerId: string;
  planName: string;
  planId: string;
  action: (
    payload: { [key: string]: string },
    callback: Function
  ) => Promise<void>;
}) {
  const history = useHistory();
  const [isLoading, load] = useLoading();
  const additionalStyle =
    buttonType === "pay"
      ? {
          label: "pay",
          shape: "pill"
        }
      : {};

  const routeMatch = useRouteMatch();

  useEffect(() => {
    (window as any).paypal
      .Buttons({
        style: {
          height: 55,
          layout: "horizontal",
          color: "blue",
          shape: "rect",
          tagline: false,
          label: "paypal",
          ...additionalStyle
        },

        createSubscription: async function(data: any, actions: any) {
          return actions.subscription.create({
            plan_id: planId
          });
        },

        onApprove: async function(data: any) {
          const payload: { [key: string]: any } = {
            paypal_subscription_id: data.subscriptionID,
            plan: planName,
            paymentProvider: "paypal"
          };

          if (routeMatch.path.includes("sign-up")) {
            const regData = (await initializeData()) as { [key: string]: any };

            payload["byPost"] = regData.byPost;
          }

          load(action(payload, history.push));
        }
      })
      .render(containerId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading;
}

export default usePaypal;
