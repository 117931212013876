import { useEffect, useState } from "react";
import axios from "axios";
import { wordPressAPI } from "../../../config";

export const useGetEventCategories = () => {
  const [categories, setCategories] = useState<any>();

  useEffect(() => {
    axios
      .get(`${wordPressAPI}/events?category=events_category`)
      .then(({ data: category }) => setCategories(category));
  }, []);

  return categories;
};

export const useEventsTotal = () => {
  const [total, setTotal] = useState<number | null>(null);

  useEffect(() => {
    if (!total) {
      axios.get(`${wordPressAPI}/events/count`).then(({ data }) => {
        setTotal(data.count);
      });
    }
  });

  return total;
};
