import { toast } from "react-toastify";
import request from "../../utils/request";
import keysToSnakeCase from "../../utils/keysToSnakeCase";
import {
  CHANGE_CARD_PENDING,
  CHANGE_CARD_FAILURE,
  CHANGE_CARD_SUCCESS,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  CHANGE_PAYMENT_METHOD_PENDING,
  CHANGE_PAYMENT_METHOD_FAILURE
} from "../constants";

const changeCard = (payload: any, callback: Function) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: CHANGE_CARD_PENDING
    });

    const { data } = await request.post("/user/card", keysToSnakeCase(payload));

    dispatch({
      type: CHANGE_CARD_SUCCESS,
      payload: data
    });

    callback();

    toast.success("Card changed successfully.");
  } catch (err) {
    toast.error("Failure changing card.");

    dispatch({
      type: CHANGE_CARD_FAILURE
    });
  }
};

export const changePaymentMethod = (payload: any, callback: Function) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: CHANGE_PAYMENT_METHOD_PENDING
    });

    const { data } = await request.post(
      "/user-subscription/payment-method",
      keysToSnakeCase(payload)
    );

    dispatch({
      type: CHANGE_PAYMENT_METHOD_SUCCESS,
      payload: data
    });

    callback();
  } catch (err) {
    toast.error("Failure changing payment method.");
    dispatch({
      type: CHANGE_PAYMENT_METHOD_FAILURE
    });
  }
};

export default changeCard;
