import styled from "styled-components";

export const StyledFooter = styled.div`
  background: ${({ theme }) => theme.deepGreen};
  color: ${({ theme }) => theme.white};
`;

export const Div = styled.div`
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    text-align: center;
  }

  @media screen and (min-width: 1000px) {
    padding-bottom: 7.8rem;
  }

  .footerBg {
    width: 19.5%;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }

  .socialBg {
    width: 13%;

    @media screen and (max-width: 1000px) {
      width: 100%;
    }
  }
`;

export const Segment = styled.div`
  display: inline-block;
  margin-top: 7.8rem;

  :first-child {
    max-width: 46.36rem;
    margin-right: 6.9rem;

    @media screen and (max-width: 1000px) {
      max-width: 100%;
      margin-right: 0;
    }
  }

  :last-child {
    margin-bottom: 7.8rem;
  }

  .title {
    font-weight: 300;
    font-size: 3rem;
    line-height: 2.1rem;
    margin-bottom: 2.9rem;
  }

  .subtitle {
    font-size: 1.2rem;
    line-height: 2.1rem;
    color: rgba(255, 255, 255, 0.5);
  }

  .text {
    font-size: 1.6rem;
    line-height: 2.1rem;
    font-weight: 300;
  }

  .link {
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    color: ${({ theme }) => theme.gray3};
    font-size: 1.4rem;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.orange};
    }

    &:not(:last-child) {
      margin-bottom: 1.9rem;
    }
  }
`;

export const Icons = styled.div`
  display: flex;

  @media screen and (max-width: 1000px) {
    justify-content: center;
  }

  a {
    margin-right: 2.2rem;

    :first-child {
      margin-right: 1.3rem;
    }
  }
`;

export const Copyright = styled.footer`
  background: ${({ theme }) => theme.black};
  color: ${({ theme }) => theme.white};
  padding: 2.2rem 0;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 300;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .copyrights {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-bottom: 1rem;

    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  @media screen and (max-width: 747px) {
    flex-direction: column;

    .footerlinks {
      margin-top: 1.25rem;
    }
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  span:not(:last-child) {
    padding-right: 2rem;
  }
`;
