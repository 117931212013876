import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import { Elements, StripeProvider } from "react-stripe-elements";
import { Modal, Spacer, CenterBox } from "../../shared";
import CardForm from "./CardForm";
import { stripeKey } from "../../../config";

const H2 = styled.h2`
  font-size: 3rem;
  margin-bottom: 2rem;
  font-weight: 300;
`;

const Warning = styled.p`
  font-size: 1.6rem;
  padding: 0 2rem;
  text-align: center;
  font-weight: 300;
  line-height: 150%;

  strong.warning {
    color: red;
  }
`;

const ChangeCard: React.FC<{
  modalActive: boolean;
  toggleModal: () => void;
}> = ({ modalActive, toggleModal }) => {
  const subscription = useSelector(
    ({ subscription }: any) => subscription.data
  );

  return (
    <Modal
      title={
        subscription.subscription_provider === "stripe"
          ? "Change Card"
          : "Change Payment Method"
      }
      modalActive={modalActive}
      toggleModal={toggleModal}
    >
      {subscription.subscription_provider === "paypal" && (
        <Warning>
          <strong className="warning">Warning:</strong> To change to a different
          payment method, your current subscription with PayPal will be
          cancelled. You will still have full access to the website through the
          next billing cycle on{" "}
          <strong>{format(subscription.expiry, "DD/MM/YYYY")}</strong>, after
          which you would need to resubscribe with your new payment method.
        </Warning>
      )}

      <Spacer height={3.1} />

      <CenterBox>
        <H2>Enter a new card</H2>
      </CenterBox>
      <StripeProvider apiKey={stripeKey}>
        <Elements>
          <CardForm toggleModal={toggleModal} />
        </Elements>
      </StripeProvider>
    </Modal>
  );
};

export default ChangeCard;
