import styled, { css } from "styled-components";

interface Props {
  readonly secondary?: boolean;
  readonly theme: any;
  readonly large?: boolean;
  readonly href?: string;
  readonly to?: string;
  readonly rel?: string;
  readonly target?: string;
}

const Button = styled.button<Props>`
  border-radius: 8rem;
  font-size: 1.4rem;
  font-weight: 400;
  border: 1px solid ${({ theme }) => theme.orange};
  padding: 0.79rem 2.4rem;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.orange};

  @media screen and (max-width: 746px) {
    margin-bottom: 2.5rem;
    padding: 2.2rem 2.2rem;
    width: 100%;
  }

  &:hover {
    ${({ theme }) => css`
      background: ${theme.white};
      color: ${theme.orange};
    `}
  }

  ${({ secondary, theme }) =>
    secondary &&
    css`
      background: ${theme.deepGreen};
      border-color: ${theme.deepGreen};

      &:hover {
        background: ${theme.white};
        color: ${theme.deepGreen};
      }
    `}

  &:disabled {
    background: ${({ theme }) => theme.gray2};
    cursor: not-allowed;
    border-color: ${({ theme }) => theme.gray2};
    color: ${({ theme }) => theme.white};
  }

  ${({ large }) =>
    large &&
    css`
      padding: 2rem 4rem;
      border-radius: 8rem;
      font-size: 2rem;
    `}
`;

export default Button;
