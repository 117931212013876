import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Case from "case";
import "react-table/react-table.css";
import { format } from "date-fns";
import { PageContainer, Spacer, PageLoader } from "../../../shared";
import { H1, HR, GoBack } from "../../../Dashboard/styles";
import { ReactComponent as BackIcon } from "../../../../svgs/caret-left.svg";
import { PageTitle } from "../../../shared/PageTitle";
import { useGetGifts } from "./hooks";
import useLoading from "../../../../hooks/useLoading";
import { Header, Column } from "../style";
import { formatToGBP } from "../../../../utils/currency";

const columns = [
  {
    Header: () => (
      <Header>
        <h3>Full name</h3>
      </Header>
    ),
    id: "firstName",
    accessor: (d: { d: { [key: string]: string }[] }) => d,
    Cell: (props: { value: any }) => (
      <Column>
        <Link
          to={`/admin/dashboard/gifts/${props.value.id}`}
          className="number"
        >
          {props.value.title +
            " " +
            props.value.first_name +
            " " +
            props.value.last_name}
        </Link>
      </Column>
    )
  },
  {
    Header: () => (
      <Header>
        <h3>Email</h3>
      </Header>
    ),
    id: "email",
    accessor: (d: { d: any }) => d,
    Cell: (props: { value: any }) => (
      <Column>
        <Link
          to={`/admin/dashboard/gifts/${props.value.id}`}
          className="number"
        >
          {props.value.email}
        </Link>
      </Column>
    )
  },
  {
    Header: () => (
      <Header>
        <h3>Plan</h3>
      </Header>
    ),
    id: "lastName",
    accessor: (d: { d: any }) => d,
    Cell: (props: { value: any }) => (
      <Column>
        <Link
          to={`/admin/dashboard/gifts/${props.value.id}`}
          className="number"
        >
          {Case.capital(props.value.plan)}
        </Link>
      </Column>
    )
  },
  {
    Header: (props: any) => (
      <Header>
        <h3>Amount</h3>
      </Header>
    ),
    id: "email",
    accessor: (d: { d: any }) => d,
    Cell: (props: { value: any }) => (
      <Column>
        <Link
          to={`/admin/dashboard/gifts/${props.value.id}`}
          className="number"
        >
          {formatToGBP(props.value.amount)}
        </Link>
      </Column>
    )
  },
  {
    Header: (props: any) => (
      <Header>
        <h3>Date</h3>
      </Header>
    ),
    id: "email",
    accessor: (d: { d: any }) => d,
    Cell: (props: { value: any }) => (
      <Column>
        <Link
          to={`/admin/dashboard/gifts/${props.value.id}`}
          className="number"
        >
          {format(props.value.created_at, "DD/MM/YYYY")}
        </Link>
      </Column>
    )
  }
];

const AllGiftings = () => {
  const [isLoading, load] = useLoading();
  const [gifts, getGifts] = useGetGifts();

  useEffect(() => {
    load(getGifts());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PageTitle title="All Gifts" description="Manage all ASA UK Gifts" />
      <PageContainer>
        <Spacer height={8.9} />

        <H1>
          All
          <br />
          Gifts
        </H1>

        <HR />

        <GoBack>
          <Link to="/admin/dashboard">
            <BackIcon />
            Go Back
          </Link>
        </GoBack>

        <Spacer height={7.5} />

        {isLoading && <PageLoader />}

        {!isLoading && gifts.data && (
          <>
            <ReactTable
              data={gifts.data}
              columns={columns}
              defaultPageSize={10}
              minRows={1}
              showPagination={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              manual
              pages={gifts.last_page}
              previousText="Previous Lists"
              nextText="Next Lists"
              loadingText="Loading Donations.."
              noDataText="No rows found"
              onFetchData={state => {
                getGifts(state.page + 1, state.pageSize);
              }}
            />
          </>
        )}

        <Spacer height={10.5} />
      </PageContainer>
    </>
  );
};

export default AllGiftings;
