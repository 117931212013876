import React from "react";
import { useDispatch } from "react-redux";
import styled, { css } from "styled-components";
import { SET_REG_STEPS } from "../../redux/constants";

const Wrapper = styled.div`
  margin-bottom: 8rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 6rem;
  }
`;

const StepCount = styled.p`
  font-size: 1.4rem;
  line-height: 150%;
  margin-bottom: 2.3rem;

  @media screen and (max-width: 992px) {
    padding-top: 3rem;
  }
`;

const StepsCounter = styled.div`
  width: 100%;
  height: 0.3rem;
  background: ${({ theme }) => theme.offWhite};
  border-radius: 2.1rem;
  display: flex;
`;

const Step = styled.div<{ active: boolean; disabled?: boolean }>`
  flex: 1;
  height: 100%;
  background: transparent;
  cursor: pointer;

  &:hover {
    background: #dd5d0f;
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
    `}

  ${({ active, theme }) =>
    active &&
    css`
      background: ${theme.orange};
      border-radius: 2.1rem;
    `}
`;

interface Props {
  readonly maxSteps?: number;
  readonly step?: any;
  readonly activateLinks?: boolean;
}

const Steps: React.FC<Props> = ({ step, maxSteps, activateLinks }) => {
  const dispatch = useDispatch();

  return (
    <Wrapper>
      <StepCount>
        Step {step} of {maxSteps}
      </StepCount>

      <StepsCounter>
        {Array(maxSteps)
          .fill("")
          .map((_, index) => {
            const currentStep = index + 1;

            if (activateLinks) {
              return (
                <Step
                  key={`step${currentStep}`}
                  active={step === currentStep}
                  disabled={currentStep >= step}
                  onClick={() => {
                    dispatch({
                      type: SET_REG_STEPS,
                      payload: resolvePayload(currentStep)
                    });
                  }}
                />
              );
            }

            return (
              <Step key={`step${currentStep}`} active={step === currentStep} />
            );
          })}
      </StepsCounter>
    </Wrapper>
  );
};

const resolvePayload = (currentStep: number) => {
  if (currentStep === 1) {
    return {
      step1Completed: false,
      step2Completed: false,
      step3Completed: false,
      step4Completed: false
    };
  }

  if (currentStep === 2) {
    return {
      step1Completed: true,
      step2Completed: false,
      step3Completed: false,
      step4Completed: false
    };
  }

  if (currentStep === 3) {
    return {
      step1Completed: true,
      step2Completed: true,
      step3Completed: false,
      step4Completed: false
    };
  }

  if (currentStep === 4) {
    return {
      step1Completed: true,
      step2Completed: true,
      step3Completed: true,
      step4Completed: false
    };
  }
};

Steps.defaultProps = {
  maxSteps: 4,
  step: 1,
  activateLinks: false
};

export default Steps;
