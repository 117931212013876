import React, { useEffect, useState, useContext } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import styled, { ThemeContext } from "styled-components";
import Loader from "react-loader-spinner";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  PageContainer,
  Spacer,
  CenterBox,
  PageLoader,
  Modal,
  Button
} from "../../../shared";
import { PageTitle } from "../../../shared/PageTitle";
import { H1, TabsContainer } from "../../../Dashboard/styles";
import { GoBack } from "../../../Dashboard/styles";
import { ReactComponent as BackIcon } from "../../../../svgs/caret-left.svg";
import PersonalInformation from "./PersonalInformation";
import BusinessInformation from "./BusinessInformation";
import MembershipInformation from "./MembershipInformation";
import { SubscriberContainer, CreateUserBtn } from "../style";
import Flex from "../shared/Flex";
import {
  useGetSubscriber,
  useDeactivateSubscriber,
  useDeleteSubscriber,
  useActivateSubscriber
} from "./hooks";
import useLoading from "../../../../hooks/useLoading";

const BackToSubscribers = styled(GoBack)`
  width: 100%;
  height: 12.2rem;
  padding: 0;
  margin: 0;
  background: ${({ theme }) => theme.offWhite2};
`;

const SingleSubscriber: React.FC<RouteComponentProps> = ({
  match: { params },
  history
}) => {
  const { subscriberID }: any = params;

  useEffect(() => {
    load(getSubscriber(subscriberID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriberID]);

  const [subscriber, getSubscriber] = useGetSubscriber();
  const [isLoading, load] = useLoading();
  const [modalLoading, loadModalAction] = useLoading();
  const [deactivateModalState, setDeactivateModalState] = useState(false);
  const [deleteModalState, setDeleteModalState] = useState(false);
  const [activateModalState, setActivateModalState] = useState(false);
  const deactivateSubscriber = useDeactivateSubscriber();
  const deleteSubscriber = useDeleteSubscriber();
  const activateSubscriber = useActivateSubscriber();
  const theme = useContext(ThemeContext);

  const toggleDeactivateModal = () =>
    setDeactivateModalState(!deactivateModalState);

  const toggleDeleteModal = () => setDeleteModalState(!deleteModalState);
  const toggleActivateModal = () => setActivateModalState(!activateModalState);

  return (
    <>
      {isLoading && <PageLoader />}

      {!isLoading && subscriber.data && (
        <>
          <PageContainer>
            <PageTitle
              title={`${subscriber.data.title} ${subscriber.data.first_name} ${subscriber.data.last_name}'s Subscription Details`}
              description={`${subscriber.data.title} ${subscriber.data.first_name} ${subscriber.data.last_name}'s Subscription Details on ASA UK`}
            />
            <Spacer height={8.9} />

            <H1>
              {`${subscriber.data.title} ${subscriber.data.first_name}`} <br />{" "}
              {`${subscriber.data.last_name}'s Subscription Details`}
            </H1>

            <Spacer height={9.8} />
            <Flex>
              <CreateUserBtn onClick={toggleDeleteModal}>
                &minus; Delete Member
              </CreateUserBtn>
              {subscriber.data.status === "inactive" &&
                subscriber.data.subscription.subscription_provider ===
                  "gift" && (
                  <CreateUserBtn onClick={toggleActivateModal}>
                    &#10003; Activate Member
                  </CreateUserBtn>
                )}

              {["active", "pending"].includes(subscriber.data.status) && (
                <CreateUserBtn onClick={toggleDeactivateModal}>
                  &#10005; Deactivate Member
                </CreateUserBtn>
              )}
            </Flex>
            <Spacer height={3.8} />
          </PageContainer>
          <TabsContainer>
            <Tabs>
              <TabList>
                <PageContainer>
                  <div className="tabs">
                    <Tab>Personal Information</Tab>
                    <Tab>Business Information</Tab>
                    <Tab>Membership Information</Tab>
                  </div>
                </PageContainer>
              </TabList>

              <PageContainer>
                <SubscriberContainer>
                  <Spacer height={7.4} />
                  <TabPanel>
                    <PersonalInformation user={subscriber.data} />
                  </TabPanel>
                  <TabPanel>
                    <BusinessInformation user={subscriber.data} />
                  </TabPanel>
                  <TabPanel>
                    <MembershipInformation
                      membership={subscriber.data.subscription}
                      member={{
                        id: subscriber.data.id,
                        firstName: subscriber.data.first_name,
                        lastName: subscriber.data.last_name
                      }}
                    />
                  </TabPanel>
                </SubscriberContainer>
              </PageContainer>
            </Tabs>
          </TabsContainer>
        </>
      )}

      <Spacer height={13.5} />

      <BackToSubscribers>
        <CenterBox>
          <Link to="/admin/dashboard/subscribers">
            <BackIcon />
            Go Back
          </Link>
        </CenterBox>
      </BackToSubscribers>

      {subscriber.data && (
        <Modal
          toggleModal={toggleDeactivateModal}
          modalActive={deactivateModalState}
          title="Deactivate member?"
        >
          <CenterBox>
            <P>Are you sure you want to deactivate member?</P>
          </CenterBox>
          <CenterBox>
            <Button
              large
              disabled={modalLoading || subscriber.data.status === "inactive"}
              onClick={() =>
                loadModalAction(
                  deactivateSubscriber(
                    subscriber.data.id,
                    toggleDeactivateModal
                  )
                )
              }
            >
              {modalLoading ? (
                <Loader
                  type="Oval"
                  color={theme.white}
                  height={20}
                  width={20}
                />
              ) : (
                "Deactivate"
              )}
            </Button>
          </CenterBox>
        </Modal>
      )}

      {subscriber.data && (
        <Modal
          toggleModal={toggleDeleteModal}
          modalActive={deleteModalState}
          title="Delete member?"
        >
          <CenterBox>
            <P>Are you sure you want to delete member?</P>
          </CenterBox>
          <CenterBox>
            <Button
              large
              disabled={modalLoading}
              onClick={() =>
                loadModalAction(
                  deleteSubscriber(subscriber.data.id, () =>
                    history.push("/admin/dashboard/subscribers")
                  )
                )
              }
            >
              {modalLoading ? (
                <Loader
                  type="Oval"
                  color={theme.white}
                  height={20}
                  width={20}
                />
              ) : (
                "Delete"
              )}
            </Button>
          </CenterBox>
        </Modal>
      )}

      {subscriber.data && (
        <Modal
          toggleModal={toggleActivateModal}
          modalActive={activateModalState}
          title="Activate delete?"
        >
          <CenterBox>
            <P>Are you sure you want to activate member?</P>
          </CenterBox>
          <CenterBox>
            <Button
              large
              disabled={modalLoading}
              onClick={() =>
                loadModalAction(
                  activateSubscriber(subscriber.data.id, toggleActivateModal)
                )
              }
            >
              {modalLoading ? (
                <Loader
                  type="Oval"
                  color={theme.white}
                  height={20}
                  width={20}
                />
              ) : (
                "Activate"
              )}
            </Button>
          </CenterBox>
        </Modal>
      )}
    </>
  );
};

const P = styled.p`
  font-size: 1.8rem;
  margin-bottom: 3rem;
`;

export default SingleSubscriber;
