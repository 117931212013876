import styled from 'styled-components';
import Button from "../shared/Button";

export const H1 = styled.h1`
  font-size: 4rem;
  font-weight: 300;
  line-height: 120%;
  letter-spacing: -0.035em;
  color: ${({ theme }) => theme.black};
`;

export const Form = styled.form`
  width: 41rem;
  margin: 7rem auto 22.9rem;
  padding: 0 3rem;
  text-align: center;

  @media screen and (max-width: 900px) {
    width: 100%;
    margin: 4rem auto 7.9rem;
  }

  a {
    color: ${({ theme }) => theme.orange};
    font-size: 1.6rem;
    line-height: 77.44%;
    text-decoration: none;
  }
`;

export const Separator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 5.8rem;

  hr {
    border: none;
    background: ${({ theme }) => theme.orange};
    height: 0.5rem;
    width: 7.9rem;
    margin-top: 4rem;
  }
`;

export const FormGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

export const LoginBtn = styled(Button)`
  margin-top: 0.8rem;
  margin-bottom: 3.5rem;
`;