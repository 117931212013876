import styled, { css } from "styled-components";

interface Props {
  errored?: boolean;
}

const Input = styled.input<Props>`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4.8rem;
  padding: 2.2rem 3.7rem;
  font-size: 2rem;
  outline: transparent;
  line-height: 21px;
  font-weight: 300;
  color: ${({ theme }) => theme.gray};

  ${({ errored, theme }) =>
    errored &&
    css`
      border: 1px solid ${theme.red};
    `}

  ${({ type }) =>
    type === "number" &&
    css`
      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
      }
      &,
      &:hover,
      &:focus {
        -moz-appearance: textfield;
      }
    `}

  &::placeholder {
    color: ${({ theme }) => theme.gray};
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export default Input;
