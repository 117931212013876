import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import { PageContainer, Spacer, Accordion, PageLoader } from "../shared";
import { H1, HR } from "./styles";
import { getFAQ } from "../../redux/actions/faq";
import { PageTitle } from "../shared/PageTitle";
import useLoading from "../../hooks/useLoading";

const FAQContainer = styled.div`
  font-size: 1.4rem;
  line-height: 150%;
`;

const FAQHead = styled.h2`
  border-radius: 0;
  font-weight: 300;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.orange};
  display: inline-block;
  padding: 0.5rem 2rem;
`;

const FAQs: React.FC<{ getFAQ: Function }> = ({ getFAQ }) => {
  const [isLoading, load] = useLoading();

  useEffect(() => {
    load(getFAQ());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const faqs = useSelector(({ faqs }: any) => faqs.data);

  return (
    <>
      <PageTitle
        title="Frequently Asked Questions"
        description="ASAUK Frequently Asked Questions"
      />
      <PageContainer>
        <Spacer height={8.9} />
        <H1>
          Frequently Asked
          <br />
          Questions
        </H1>
        <HR />

        <Spacer height={7.9} />

        {isLoading ? (
          <PageLoader />
        ) : (
          faqs &&
          faqs.map((faq: any) => (
            <FAQContainer>
              <FAQHead>{faq.category_name}</FAQHead>

              <Spacer height={5.4} />
              {faq.content.map((singleFaq: any) => (
                <>
                  <Accordion title={singleFaq.post_title}>
                    {singleFaq.post_content}
                  </Accordion>
                  <Spacer height={5.7} />
                </>
              ))}
              <Spacer height={2} />
            </FAQContainer>
          ))
        )}

        <Spacer height={20.6} />
      </PageContainer>
    </>
  );
};

export default connect(null, { getFAQ })(FAQs);
