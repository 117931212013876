import React, { Fragment } from "react";
import AriaModal from "react-aria-modal";
import { ModalArea, ModalBody, ModalHeader, SVG } from "./styles";

interface Props {
  modalActive: boolean;
  toggleModal(): void;
  title: string;
  type?: string;
}

const Modal: React.FC<Props> = ({
  modalActive,
  toggleModal,
  children,
  title,
  type
}) => {
  return modalActive ? (
    <AriaModal
      titleText={title}
      onExit={toggleModal}
      initialFocus="#modal-deactivate"
      underlayStyle={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <ModalArea sharpEdges={type === "discount"}>
        {type === "modal" && (
          <ModalHeader>
            <h1>{title}</h1>
            <button id="modal-deactivate" onClick={toggleModal}>
              <SVG viewBox="0 0 40 40">
                <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
              </SVG>
            </button>
          </ModalHeader>
        )}
        <ModalBody className={type === "discount" ? "no-padding" : ""}>
          {children}
        </ModalBody>
      </ModalArea>
    </AriaModal>
  ) : (
    <Fragment />
  );
};

Modal.defaultProps = {
  type: "modal"
};

export default Modal;
