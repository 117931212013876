import React, { useState, useContext } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Loader from "react-loader-spinner";
import { ThemeContext } from "styled-components";
import Case from "case";
import { useSelector, connect } from "react-redux";
import { Spacer, Steps, Button, CenterBox, FullPageLoader } from "../../shared";
import { parseQueryString } from "../../../utils/queryString";
import {
  Container,
  H1,
  P,
  StepsWrapper,
  Billing,
  PayPalButton,
  Warning,
  PaymentDets,
} from "./styles";
import { prices } from "./metaData";
import { upgradePlan, renewPlan } from "../../../redux/actions/plan";
import ChangeCard from "../ChangeCard";
import { PageTitle } from "../../shared/PageTitle";
import PaymentDetails from "./PaymentDetails";
import { usePay } from "./hooks";
import usePaypal from "../../../hooks/usePaypal";

interface Props extends RouteComponentProps {
  upgradePlan: Function;
  renewPlan: Function;
}

const paypalPlans: { [key: string]: any } = {
  student: process.env.REACT_APP_STUDENT,
  individual: process.env.REACT_APP_INDIVIDUAL,
  friend: process.env.REACT_APP_FRIEND,
  lifetime: process.env.REACT_APP_LIFETIME,
};

const CardCharge: React.FC<Props> = ({
  history,
  upgradePlan,
  renewPlan,
  match,
}) => {
  const payWithPaypal = usePay(() => history.push("/dashboard/account"));
  const [loading, setLoading] = useState(false);
  const theme = useContext(ThemeContext);
  const location = useLocation();
  const isRenew = match.path.includes("renew-plan");

  const { plan } = parseQueryString(location.search);

  const isLoading = usePaypal({
    containerId: "#paypal-button-holder",
    action: payWithPaypal,
    planId: paypalPlans[plan],
    planName: plan,
    buttonType: "pay",
  });

  const user = useSelector((state: any) => state.user.data);

  const [modalState, setModalState] = useState(false);
  const [paymentModalState, setPaymentModalState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const togglePaymentModal = () => {
    setPaymentModalState(!paymentModalState);
  };

  const action = isRenew ? renewPlan : upgradePlan;

  return (
    <>
      <PageTitle
        title={`Lets finish this up! Upgrade to ${plan.charAt(0).toUpperCase() +
          plan.slice(1)}'s Plan`}
        description={`Lets finish this up! Upgrade to ${plan
          .charAt(0)
          .toUpperCase() + plan.slice(1)} Plan`}
      />
      <Container>
        <Spacer height={11} />

        <H1>Lets finish this up!</H1>

        <Spacer height={1.3} />

        <P>{Case.capital(plan)} Plan</P>

        <Spacer height={5.8} />

        <StepsWrapper>
          <Steps maxSteps={2} step={2} />
        </StepsWrapper>

        <Billing>
          <p className="title">Bill</p>

          <Spacer height={2} />

          <p className="price">
            <sup>£</sup>
            {prices[plan].price}
            <sub>.00</sub>
          </p>

          <PaymentDets>
            <p className="title">Payment Information:</p>
          </PaymentDets>

          <p className="name">
            {user.title} {user.first_name} {user.last_name}
          </p>

          <p className="card">**** **** **** {user.card}</p>

          <Button large secondary onClick={toggleModal}>
            Change Card
          </Button>

          <Spacer height={3} />
          <PaymentDets>
            <Spacer height={0.5} />

            <Spacer height={1} />

            <Button
              large
              type="button"
              disabled={loading}
              onClick={() => {
                setLoading(true);

                action(
                  {
                    plan,
                    paymentMethod: "stripe",
                  },
                  history.push
                );
              }}
            >
              {loading ? (
                <Loader
                  type="Oval"
                  color={theme.white}
                  height={20}
                  width={20}
                />
              ) : isRenew ? (
                "Renew My Plan"
              ) : (
                "Upgrade My Plan"
              )}
            </Button>

            <Spacer height={6} />

            <CenterBox>
              <p className="title">Or Upgrade with PayPal:</p>
            </CenterBox>
            {!isRenew && (
              <CenterBox>
                <Warning>
                  <strong>Warning</strong>: To upgrade your account with PayPal,
                  your ongoing subscription will be cancelled and a new
                  subscription will be created.
                  <Spacer height={2} />
                </Warning>
              </CenterBox>
            )}

            <CenterBox>
              <PayPalButton id="paypal-button-holder" hide={false} />
            </CenterBox>
          </PaymentDets>

          <Spacer height={22.3} />
        </Billing>

        <ChangeCard modalActive={modalState} toggleModal={toggleModal} />

        <PaymentDetails
          modalActive={paymentModalState}
          toggleModal={togglePaymentModal}
          plan={plan}
        />
      </Container>

      {isLoading && <FullPageLoader />}
    </>
  );
};

export default connect(
  null,
  {
    upgradePlan,
    renewPlan,
  }
)(CardCharge);
