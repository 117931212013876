import styled from "styled-components";
import { Button } from "../../shared";

export const Container = styled.div`
  text-align: center;
  width: 90%;
  max-width: 116rem;
  margin: 0 auto;
`;

export const H1 = styled.h1`
  font-size: 3.6rem;
  line-height: 120%;
  color: ${({ theme }) => theme.black2};
  font-weight: 300;
`;

export const P = styled.p`
  font-size: 1.6rem;
  line-height: 120%;
`;

export const StepsWrapper = styled.div`
  max-width: 74.8rem;
  margin: 0 auto;
`;

export const Plan = styled.div`
  height: 55.8rem;
  min-width: 24.1rem;
  width: 24.1rem;
  position: relative;
  padding: 5.5rem 2.8rem 5rem;
  border-radius: 15px;
  background: ${({ theme }) => theme.orange};
  color: ${({ theme }) => theme.white};

  @media screen and (max-width: 992px) {
    width: 100%;
    margin-top: 3rem;
  }

  &:nth-child(even) {
    background: ${({ theme }) => theme.gray6};
    color: ${({ theme }) => theme.black2};
  }

  .title {
    text-transform: uppercase;
    font-size: 3rem;
    line-height: 2.2rem;
    font-weight: 700;
  }

  .price {
    font-size: 2.4rem;
    font-weight: 400;
    position: relative;
  }

  .description {
    font-size: 1.4rem;
    line-height: 150%;
    margin-top: 4.5rem;
    text-align: left;
  }
`;

export const Plans = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 992px) {
    flex-direction: column;
    margin: 0 3rem;
  }
`;

export const CTABtn = styled(Button)`
  padding: 0;
  margin: 0;
  width: 18.5rem;
  height: 4.3rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: ${({ theme }) => theme.black};
  background: ${({ theme }) => theme.offWhite};
`;

export const BtnCont = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 5rem;

  @media screen and (max-width: 992px) {
    position: relative;
    margin-top: 50%;
  }
`;

export const Billing = styled.div`
  text-align: center;

  sup,
  sub {
    font-size: 1.4rem;
    font-weight: 300;
  }

  .title {
    font-size: 2.4rem;
    line-height: 99.6%;
    font-weight: 300;
  }

  hr {
    border: none;
    background: ${({ theme }) => theme.gray2};
    height: 1px;
    width: 54.5rem;
    margin: 0 auto;
  }

  .price {
    font-size: 9.6rem;
    line-height: 99.6%;
    color: ${({ theme }) => theme.black2};
    margin-bottom: 2.1rem;
  }

  .name {
    font-size: 1.8rem;
    line-height: 120%;
    font-weight: 400;
  }

  .card {
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 120%;
    color: ${({ theme }) => theme.gray3};
    margin-bottom: 1.5rem;
  }
`;

export const PayPalButton = styled.div<{ hide: boolean }>`
  display: ${({ hide }) => (hide ? "none" : "normal")};
  width: 25rem;
`;

export const Warning = styled.p`
  strong {
    color: red;
  }

  font-size: 1.2rem;
  width: 40rem;
  line-height: 150%;
`;

export const PaymentDets = styled.div`
  .title {
    color: ${({ theme }) => theme.orange};
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 2rem;
  }
`;
