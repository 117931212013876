import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import styled from "styled-components";
import Carousel from "nuka-carousel";
import {
  NewsGrid,
  NewsTitle,
  IndividualNewsContainer,
  NewsContent,
  SVG
} from "./styles";
import { getDiscounts } from "../../redux/actions/discount";
import { Link } from "react-router-dom";
import ViewAll from "./ViewAll";
import { ReactComponent as RightIcon } from "../../svgs/caret-right.svg";
import { Modal, CenterBox, RightBox, Spacer } from "../shared";

const DeactivateBtn = styled.button`
  background: transparent;
  border: none;
  padding: 0.4rem;
  cursor: pointer;

  &:focus {
    outline-color: transparent;
  }
`;

const Code = styled.div`
  border: 1px dashed ${({ theme }) => theme.gray3};
  width: 80%;
  height: 7rem;
`;

const DiscountTitle = styled.p`
  text-align: center;
  font-size: 2rem;
  line-height: 150%;
`;

const DownloadLink = styled.a`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.orange};
`;

const params = {
  page: 1,
  perPage: 3
};

const Discounts: React.FC<{ getDiscounts: Function }> = ({ getDiscounts }) => {
  const discounts = useSelector(({ discounts }: any) => discounts.data);
  const [modalActive, setModalActive] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<any>(null);

  useEffect(() => {
    getDiscounts(params);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setModalActive(!modalActive);
  };

  return (
    <NewsGrid>
      <NewsTitle>
        <div className="discounts">
          <p className="title">Discounts</p>

          <ViewAll to="/dashboard/discounts">
            View all <RightIcon />
          </ViewAll>
        </div>
      </NewsTitle>
      <IndividualNewsContainer>
        <div className="show-mobile">
          <Carousel
            withoutControls={false}
            slidesToShow={1}
            enableKeyboardControls={true}
            cellSpacing={10}
            renderBottomLeftControls={() => <></>}
            renderBottomRightControls={() => <></>}
            renderTopCenterControls={() => <></>}
            renderCenterLeftControls={() => <> </>}
            renderCenterRightControls={() => <></>}
          >
            {discounts &&
              discounts.data.slice(0, 3).map((discount: any) => (
                <NewsContent
                  key={discount.id}
                  featuredImage={discount.featured_image}
                  className="newsContent"
                >
                  <Link
                    to="#"
                    onClick={() => {
                      toggleModal();
                      setSelectedDiscount(discount);
                    }}
                  >
                    <div className="headimage">
                      <div />
                    </div>
                  </Link>

                  <p className="source">{discount.store_name}</p>

                  <p
                    className="headline"
                    onClick={() => {
                      toggleModal();
                      setSelectedDiscount(discount);
                    }}
                  >
                    {discount.discount_name &&
                      (discount.discount_name.length < 51
                        ? discount.discount_name
                        : discount.discount_name.substr(0, 50) + "...")}
                  </p>

                  <button
                    className="view-deal"
                    onClick={() => {
                      toggleModal();
                      setSelectedDiscount(discount);
                    }}
                  >
                    View deal
                  </button>
                </NewsContent>
              ))}
          </Carousel>
        </div>
        {discounts &&
          discounts.data.slice(0, 3).map((discount: any) => (
            <NewsContent
              key={discount.id}
              featuredImage={discount.featured_image}
              className="newsContent show-desktop"
            >
              <p
                className="cursor"
                onClick={() => {
                  toggleModal();
                  setSelectedDiscount(discount);
                }}
              >
                <div className="headimage">
                  <div />
                </div>
              </p>

              <p className="source">{discount.store_name}</p>

              <p
                className="headline"
                onClick={() => {
                  toggleModal();
                  setSelectedDiscount(discount);
                }}
              >
                {discount.discount_name.length < 51
                  ? discount.discount_name
                  : discount.discount_name.substr(0, 50) + "..."}
              </p>

              <button
                className="view-deal"
                onClick={() => {
                  toggleModal();
                  setSelectedDiscount(discount);
                }}
              >
                View deal
              </button>
            </NewsContent>
          ))}
      </IndividualNewsContainer>
      <Modal
        title="discount"
        modalActive={modalActive}
        toggleModal={toggleModal}
        type="discount"
      >
        <RightBox>
          <DeactivateBtn id="modal-deactivate" onClick={toggleModal}>
            <SVG viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </SVG>
          </DeactivateBtn>
        </RightBox>

        <DiscountTitle>
          {selectedDiscount && selectedDiscount.post_title}
        </DiscountTitle>

        <Spacer height={3} />

        <CenterBox>
          <Code>
            <CenterBox>
              <h1>{selectedDiscount && selectedDiscount.discounts_code}</h1>
            </CenterBox>
          </Code>
        </CenterBox>

        <Spacer height={2} />

        {selectedDiscount && selectedDiscount.discount_document_upload && (
          <CenterBox>
            <DownloadLink
              href={selectedDiscount.discount_document_upload}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download Instructions
            </DownloadLink>
          </CenterBox>
        )}

        <Spacer height={4} />
      </Modal>
    </NewsGrid>
  );
};

export default connect(null, { getDiscounts })(Discounts);
