export const plans = ["student", "individual", "friend", "lifetime"];
export const prices: {
  [key: string]: { desc: string; price: number };
} = {
  student: {
    desc:
      "Enjoy our annual membership and accompanying benefits at a reduced student rate. N.B. Applications for student membership will require confirmation of your full-time student status from your university or college.",
    price: 40
  },
  individual: {
    desc:
      "Joint annual membership of the ASA UK and the African Studies Association of the UK (ASAUK), including free subscription to African Affairs, free or priority access to our events, networking opportunities and much more.",
    price: 85
  },
  friend: {
    desc:
      "As a friend of the ASA UK, you will enjoy the full range of membership benefits whilst also supporting our core charitable work. Perfect for those who share our values and vision.",
    price: 120
  },
  lifetime: {
    desc:
      "For those who are real advocates of the ASA UK and ASA UK and want to support our charitable work for years to come.",
    price: 1500
  }
};
