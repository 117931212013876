import React from "react";
import { connect } from "react-redux";
import { Footer, IdleTimer } from "../../shared";
import { AdminPrimaryNav } from "./shared";
import Home from "./Home";
import AllSubscribers from "./AllSubscribers";
import SingleSubscriber from "./AllSubscribers/SingleSubscriber";
import AllGiftings from "./AllGiftings";
import SingleGift from "./AllGiftings/SingleGift";
import { PageTitle } from "../../shared/PageTitle";
import AllDonations from "./AllDonations";
import SingleDonation from "./AllDonations/SingleDonation";
import Account from "./Account";
import AdminGate from "../../../Routes/AdminGate";

const AdminDashboard: React.FC = () => {
  return (
    // 1 minute * 15
    <IdleTimer timeout={60000 * 15}>
      <PageTitle
        title="Admin Dashboard"
        description="Welcome to the Admin Dashboard"
      />
      <AdminPrimaryNav />
      <AdminGate path="/admin/dashboard" component={Home} exact />
      <AdminGate path="/admin/dashboard/account" component={Account} exact />
      <AdminGate
        path="/admin/dashboard/subscribers"
        component={AllSubscribers}
        exact
      />
      <AdminGate
        path="/admin/dashboard/subscribers/:subscriberID"
        component={SingleSubscriber}
        exact
      />
      <AdminGate path="/admin/dashboard/gifts" component={AllGiftings} exact />
      <AdminGate
        path="/admin/dashboard/gifts/:giftID"
        component={SingleGift}
        exact
      />
      <AdminGate
        path="/admin/dashboard/donations"
        component={AllDonations}
        exact
      />
      <AdminGate
        path="/admin/dashboard/donations/:donationID"
        component={SingleDonation}
        exact
      />
      <Footer />
    </IdleTimer>
  );
};

export default connect(null, null)(AdminDashboard);
