import React, { useState, useRef } from "react";
import styled from "styled-components";
import { ReactComponent as Chevron } from "../../svgs/chevron.svg";

interface Props {
  title: string;
}

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;

  a {
    color: ${({ theme }) => theme.orange};
  }

  .accordion {
    cursor: pointer;
    display: flex;
    align-items: center;
    border: none;
    outline: none;
    padding-bottom: 1rem;
    border-bottom: 3px solid ${({ theme }) => theme.black};
    background: transparent;
  }

  .title {
    color: ${({ theme }) => theme.black};
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 2.9rem;
    text-align: left;
  }

  .icon {
    margin-left: auto;
    transform: scaleY(-1);
    transition: transform 0.2s ease;
  }

  .rotate {
    transform: scaleY(1);
  }

  .content {
    overflow: hidden;
    transition: max-height 0.2s ease;
  }

  .text {
    padding-top: 3.8rem;
    font-size: 1.6rem;
    line-height: 150%;
  }
`;

const Accordion: React.FC<Props> = ({ title, children }) => {
  const [active, setActive] = useState("");
  const [height, setHeight] = useState("0rem");
  const [rotate, setRotate] = useState("icon");

  const accRef = useRef<HTMLDivElement>(null);

  const toggleAccordion = () => {
    setActive(active === "" ? "active" : "");
    setHeight(
      active === "active"
        ? "0"
        : `${accRef.current && accRef.current.scrollHeight}px`
    );
    setRotate(active === "active" ? "icon" : "icon rotate");
  };

  return (
    <AccordionSection>
      <button className={`accordion ${active}`} onClick={toggleAccordion}>
        <span className="title">{title}</span>
        <Chevron className={`${rotate}`} />
      </button>

      <div ref={accRef} className="content" style={{ maxHeight: `${height}` }}>
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: children as string }}
        />
      </div>
    </AccordionSection>
  );
};

export default Accordion;
