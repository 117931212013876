import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, connect } from "react-redux";
import { Checkbox, Spacer } from "../shared";
import { getNewsletters } from "../../redux/actions/newsletters";

const KeepInTouch: React.FC<{
  getNewsletters: Function;
  selectedNewsletters: string[];
  setSelectedNewsletters: Function;
  setField: Function;
}> = ({
  getNewsletters,
  selectedNewsletters,
  setSelectedNewsletters,
  setField
}) => {
  const [mailingLists, showMailingLists] = useState(false);
  const [byPost, setByPost] = useState(false);

  const newsletters = useSelector((state: any) => state.newsletters.data);
  useEffect(() => {
    getNewsletters();
  }, [getNewsletters]);

  return (
    <KeepInTouchBox>
      <p className="title">Keeping in touch</p>

      <p className="paragraph">
        We would like to keep you updated on our activities, future appeals and
        how your support is making a difference. Please let us know if you would
        like to be contacted and, if so, how you might like to hear from us.
      </p>

      <Checkbox
        checked={mailingLists}
        onChange={() => {
          const bool = !mailingLists;
          setField("gdpr", bool);
          showMailingLists(bool);
        }}
        label={<strong>Yes I am happy for the ASA UK to email me</strong>}
      />

      {mailingLists && (
        <>
          <p className="paragraph last">
            We have a number of newsletters from the different programmes we
            run, which any member of the public can subscribe to. Please select
            the newsletters you would like to receive below.
          </p>

          <Events>
            {newsletters &&
              newsletters.mailing_lists.map((interest: any) => (
                <Checkbox
                  key={interest.id}
                  onChange={({ target: { checked } }: any) => {
                    if (checked) {
                      setSelectedNewsletters([
                        ...selectedNewsletters,
                        interest.id
                      ]);
                    } else {
                      setSelectedNewsletters(
                        selectedNewsletters.filter(
                          selected => selected !== interest.id
                        )
                      );
                    }
                  }}
                  small
                  checked={selectedNewsletters.includes(interest.id)}
                  label={<span>{interest.name}</span>}
                />
              ))}
          </Events>
        </>
      )}

      <Spacer height={3.3} />
      <Checkbox
        small
        label={
          <span>Yes, I am happy for the ASA UK to contact me by post</span>
        }
        onChange={() => {
          const bool = !byPost;
          setByPost(bool);
          setField("byPost", bool);
        }}
      />

      {byPost && (
        <p className="paragraph last">
          Please note you can change your preferences by emailing{" "}
          <a href="mailto:ras@soas.ac.uk">ras@soas.ac.uk</a>. See our{" "}
          <a
            href="https://royalafricansociety.org/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>{" "}
          for more information.
        </p>
      )}
    </KeepInTouchBox>
  );
};

const Events = styled.div`
  label {
    margin-right: 1.8rem;
    font-size: 1.4rem;
  }

  line-height: 200%;

  width: 93%;
  margin: 0 auto;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1.9rem;
`;

const KeepInTouchBox = styled.div`
  text-align: left;
  width: 100%;
  background: ${({ theme }) => theme.offWhite};
  border-radius: 1rem;
  padding: 4.1rem 3.5rem;
  font-size: 1.4rem;
  border: 1px solid #e0e0e0;
  margin-top: 3.6rem;

  .title {
    margin-left: 0.1rem;
    font-size: 2.4rem;
    line-height: 140.5%;
    letter-spacing: -0.035em;
    margin-bottom: 2.1rem;
  }

  .paragraph {
    line-height: 150%;
    padding-bottom: 2.1rem;
    margin-bottom: 2.5rem;

    a {
      font-size: inherit;
    }

    &.last {
      width: 93%;
      margin: 0 auto;
      margin-top: 3.3rem;
    }
  }
`;

export default connect(null, {
  getNewsletters
})(KeepInTouch);
