import React, { useContext } from "react";
import { Formik } from "formik";
import { connect, useSelector } from "react-redux";
import Loader from "react-loader-spinner";
import { ThemeContext } from "styled-components";
import {
  Input,
  Select,
  Steps,
  InputWrapper,
  FormGroup,
  countryList,
  CenterBox,
  ScrollToTopOnMount
} from "../shared";
import {
  Form,
  H1,
  Separator,
  DateOfBirth,
  Label,
  Address,
  SignUpButton,
  DayMonthInput,
  YearInput
} from "./styles";
import { register } from "../../redux/actions/auth";
import { step1ValidationSchema } from "./validationSchemas";
import { persistRegData, resetTimer } from "./regUtils";
import formHasErrors from "../../utils/formHasErrors";
import useRedirectToDashboard from "../../hooks/useRedirectToDashboard";

const Step1: React.FC<{
  register: Function;
}> = React.memo(({ register }) => {
  useRedirectToDashboard();
  const theme = useContext(ThemeContext);
  const user = useSelector((state: any) => state.user);

  return (
    <>
      <ScrollToTopOnMount />

      <Formik
        onSubmit={registrationData => {
          persistRegData(registrationData);
          register(registrationData).then(resetTimer);
        }}
        initialValues={{
          title: "",
          firstName: "",
          lastName: "",
          genderIdentity: "",
          email: "",
          confirmEmail: "",
          password: "",
          confirmPassword: "",
          nationality: "",
          day: "",
          month: "",
          year: "",
          postCode: "",
          address: "",
          city: "",
          county: "",
          phone: "",
          country: ""
        }}
        validationSchema={step1ValidationSchema}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          errors,
          touched,
          dirty
        }) => (
          <Form onSubmit={handleSubmit}>
            <H1>
              Sign up to become an
              <br />
              ASA UK member
            </H1>

            <CenterBox>
              <Separator>
                <hr />
              </Separator>
            </CenterBox>

            <Steps step={1} activateLinks />

            <FormGroup>
              <InputWrapper>
                <Select
                  errored={Boolean(touched.title && errors.title)}
                  value={values.title}
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Title *</option>
                  <option value="Ms">Ms</option>
                  <option value="Miss">Miss</option>
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Dr">Dr</option>
                  <option value="Prof">Prof</option>
                  <option value="Sir">Sir</option>
                  <option value="Revd">Revd</option>
                  <option value="Dame">Dame</option>
                  <option value="Baroness">Baroness</option>
                  <option value="Lord">Lord</option>
                  <option value="Earl">Earl</option>
                  <option value="Rt Hon">Rt Hon</option>
                  <option value="Rt Hon Sir">Rt Hon Sir</option>
                </Select>

                <span className="error">{touched.title && errors.title}</span>
              </InputWrapper>

              <InputWrapper>
                <Select
                  value={values.genderIdentity}
                  errored={Boolean(
                    touched.genderIdentity && errors.genderIdentity
                  )}
                  name="genderIdentity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option>Gender *</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Prefer not to say">Prefer not to say</option>
                  <option value="Non-Binary">Non-Binary</option>
                </Select>

                <span className="error">
                  {touched.genderIdentity && errors.genderIdentity}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.firstName && errors.firstName)}
                  placeholder="First Name *"
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  required
                />

                <span className="error">
                  {touched.firstName && errors.firstName}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  placeholder="Last Name *"
                  errored={Boolean(touched.lastName && errors.lastName)}
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                <span className="error">
                  {touched.lastName && errors.lastName}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  value={values.email}
                  errored={Boolean(touched.email && errors.email)}
                  onChange={handleChange}
                  placeholder="Email *"
                  type="email"
                  name="email"
                  onBlur={handleBlur}
                  required
                />
                <span className="error">{touched.email && errors.email}</span>
              </InputWrapper>

              <InputWrapper>
                <Input
                  errored={Boolean(touched.confirmEmail && errors.confirmEmail)}
                  value={values.confirmEmail}
                  onChange={handleChange}
                  placeholder="Confirm Email *"
                  name="confirmEmail"
                  type="email"
                  onBlur={handleBlur}
                  required
                />

                <span className="error">
                  {touched.confirmEmail && errors.confirmEmail}
                </span>
              </InputWrapper>
            </FormGroup>
            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.password && errors.password)}
                  value={values.password}
                  onChange={handleChange}
                  placeholder="Password *"
                  name="password"
                  type="password"
                  onBlur={handleBlur}
                  required
                />
                <span className="error">
                  {touched.password && errors.password}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  errored={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  value={values.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm Password *"
                  name="confirmPassword"
                  type="password"
                  onBlur={handleBlur}
                  required
                />
                <span className="error">
                  {touched.confirmPassword && errors.confirmPassword}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.phone && errors.phone)}
                  value={values.phone}
                  onChange={handleChange}
                  placeholder="Phone *"
                  name="phone"
                  type="text"
                  onBlur={handleBlur}
                  required
                />

                <span className="error">{touched.phone && errors.phone}</span>
              </InputWrapper>

              <InputWrapper>
                <Select
                  errored={Boolean(touched.nationality && errors.nationality)}
                  value={values.nationality}
                  onChange={handleChange}
                  placeholder="Nationality *"
                  name="nationality"
                  onBlur={handleBlur}
                  required
                >
                  <option value="Nationality">Nationality *</option>
                  {countryList}
                </Select>

                <span className="error">
                  {touched.nationality && errors.nationality}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <DateOfBirth>
                <Label>Date of Birth *</Label>
                <FormGroup>
                  <div className="dateGroup">
                    <DayMonthInput
                      errored={Boolean(touched.day && errors.day)}
                      value={values.day}
                      name="day"
                      placeholder="DD"
                      type="number"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (Number(event.target.value) <= 31) {
                          handleChange(event);
                        }
                      }}
                      max={31}
                      onBlur={handleBlur}
                    />
                    <DayMonthInput
                      value={values.month}
                      errored={Boolean(touched.month && errors.month)}
                      name="month"
                      type="number"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        if (Number(event.target.value) <= 12) {
                          handleChange(event);
                        }
                      }}
                      placeholder="MM"
                      onBlur={handleBlur}
                    />
                    <YearInput
                      value={values.year}
                      errored={Boolean(touched.year && errors.year)}
                      onChange={handleChange}
                      name="year"
                      type="number"
                      onBlur={handleBlur}
                      placeholder="YYYY"
                      autoComplete="new-password"
                    />
                  </div>
                </FormGroup>
              </DateOfBirth>
            </FormGroup>

            <FormGroup>
              <Address>
                <Label>Billing Address</Label>
                <FormGroup>
                  <InputWrapper>
                    <Input
                      errored={Boolean(touched.postCode && errors.postCode)}
                      value={values.postCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Post Code *"
                      name="postCode"
                      type="text"
                    />

                    <span className="error">
                      {touched.postCode && errors.postCode}
                    </span>
                  </InputWrapper>
                </FormGroup>
              </Address>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.address && errors.address)}
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Address *"
                  name="address"
                  type="text"
                  required
                />

                <span className="error">
                  {touched.address && errors.address}
                </span>
              </InputWrapper>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.city && errors.city)}
                  value={values.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="City *"
                  type="text"
                  name="city"
                  required
                />
                <span className="error">{touched.city && errors.city}</span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.county && errors.county)}
                  value={values.county}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="County *"
                  name="county"
                  type="text"
                  required
                />
                <span className="error">{touched.county && errors.county}</span>
              </InputWrapper>
              <InputWrapper>
                <Select
                  errored={Boolean(touched.country && errors.country)}
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="country"
                  required
                >
                  <option value="Country">Country *</option>

                  {countryList}
                </Select>

                <span className="error">
                  {touched.country && errors.country}
                </span>
              </InputWrapper>
            </FormGroup>
            <SignUpButton
              type="submit"
              secondary
              large
              disabled={formHasErrors(errors) || user.loading || !dirty}
            >
              {user.loading && (
                <Loader
                  type="Oval"
                  color={theme.white}
                  height={20}
                  width={20}
                />
              )}
              {!user.loading && "Next"}
            </SignUpButton>
          </Form>
        )}
      </Formik>
    </>
  );
});

export default connect(null, {
  register
})(Step1);
