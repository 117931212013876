import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import startOfMonth from "date-fns/start_of_month";
import format from "date-fns/format";
import { connect, useSelector } from "react-redux";
import { H1, HR, NewsContent, GoBack } from "../styles";
import {
  PageContainer,
  Spacer,
  Controls,
  CenterBox,
  PageLoader
} from "../../shared";
import { ReactComponent as BackIcon } from "../../../svgs/caret-left.svg";
import { ReactComponent as CaretRight } from "../../../svgs/caret-right.svg";
import { NewsContainer, Underline } from "./styles";
import { getNews, getMoreNews } from "../../../redux/actions/news";
import { ViewMore } from "../AllEvents/styles";
import { PageTitle } from "../../shared/PageTitle";
import useLoading from "../../../hooks/useLoading";
import { useGetNewsCategories } from "./hooks";

const Excerpt = styled.p`
  margin-top: 1.5rem;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.gray};
  line-height: 1.9rem;
  max-height: 11.3rem;

  .excerptLink {
    color: inherit;
    display: inline-block;
    text-decoration: none;
  }
`;

const ReadMore = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  font-size: 1.4rem;

  span {
    color: ${({ theme }) => theme.gray};
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.black};

    svg {
      margin-left: 1.2rem;

      path {
        stroke: ${({ theme }) => theme.black};
      }
    }
  }
`;

const AllNews: React.FC<{ getNews: Function; getMoreNews: Function }> = ({
  getNews,
  getMoreNews
}) => {
  const [controls, setControls] = useState<{
    date: Date;
    perPage: number;
    filter: string | number;
    page: number;
  }>({
    date: startOfMonth(new Date()),
    perPage: 8,
    filter: "",
    page: 1
  });

  const [viewMoreControls, setViewMoreControls] = useState(controls);

  useEffect(() => {
    setViewMoreControls(controls);
  }, [controls]);

  const news = useSelector(({ news }: any) => news.data);

  const setDate = (date: Date) => {
    setControls({
      ...controls,
      date
    });
  };

  const setFilter = (filter: string | number) => {
    setControls({
      ...controls,
      filter
    });
  };

  const [isLoading, load] = useLoading();
  const categories = useGetNewsCategories();

  useEffect(() => {
    load(getNews(controls));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls]);

  return (
    <>
      <PageTitle title="Latest News" description="Latest news on the ASA UK" />
      <PageContainer>
        <Spacer height={8.9} />

        <H1>
          Latest
          <br />
          News
        </H1>

        <HR />

        <GoBack>
          <Link to="/dashboard">
            <BackIcon />
            Go Back
          </Link>
        </GoBack>

        <Spacer height={8.94} />

        <Controls
          view=""
          setView={() => {}}
          setFilter={setFilter}
          setDate={setDate}
          showViewControls={false}
          categories={categories}
        />

        <Spacer height={8.9} />

        <NewsContainer>
          {isLoading ? (
            <PageLoader />
          ) : (
            news.data.map((story: any) => (
              <NewsContent
                key={story.id}
                featuredImage={story.featured_img_src}
                className="newsSection"
              >
                <Link to={`/dashboard/news/${story.slug}`}>
                  <div className="headimage">
                    <div />
                  </div>
                </Link>

                <Link className="headline" to={`/dashboard/news/${story.slug}`}>
                  {story.title.length < 45
                    ? story.title
                    : story.title.substr(0, 45) + "..."}
                </Link>

                <Underline />

                <Excerpt>
                  {story.content.length < 200
                    ? story.content.replace(/<[^>]+>/g, "")
                    : story.content.substring(0, 200).replace(/<[^>]+>/g, "") +
                      "..."}
                </Excerpt>

                <ReadMore>
                  <Link to={`/dashboard/news/${story.slug}`}>
                    Read more <CaretRight />
                  </Link>

                  <span>{format(story.post_date, "MM/DD/YYYY")}</span>
                </ReadMore>
              </NewsContent>
            ))
          )}
        </NewsContainer>

        <Spacer height={17.6} />

        {!isLoading && news.pageCount > viewMoreControls.page && (
          <CenterBox>
            <ViewMore
              onClick={() => {
                const newControls = {
                  ...viewMoreControls,
                  page: viewMoreControls.page + 1
                };

                setViewMoreControls(newControls);

                getMoreNews(newControls);
              }}
            >
              View more News
              <CaretRight />
            </ViewMore>
          </CenterBox>
        )}

        <Spacer height={7.65} />
      </PageContainer>
    </>
  );
};

export default connect(null, { getNews, getMoreNews })(AllNews);
