import axios from "axios";
import localforage from "localforage";
import { toast } from "react-toastify";
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_PENDING,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_PENDING,
  CONFIRM_EMAIL_PENDING,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAILURE,
  SET_REG_STEPS,
  GET_SUB_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_PENDING,
  UPDATE_USER_FAILURE
} from "../constants";
import { apiBaseURL } from "../../config";
import keysToSnakeCase, { ObjType } from "../../utils/keysToSnakeCase";
import request from "../../utils/request";

export const register = (registrationData: ObjType) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: REGISTER_PENDING
    });

    const { day, month, year, ...regParams } = registrationData;
    regParams.dateOfBirth = `${year}-${month}-${day}`;

    await axios.post(`${apiBaseURL}/register`, keysToSnakeCase(regParams));

    dispatch({
      type: REGISTER_SUCCESS
    });

    dispatch({
      type: SET_REG_STEPS,
      payload: { step1Completed: true }
    });
  } catch (err) {
    if (err.response.status >= 400) {
      toast.error(err.response.data.error);
    }

    dispatch({
      type: REGISTER_FAILURE
    });
  }
};

export const updateUser = (payload: ObjType) => async (dispatch: Function) => {
  dispatch({
    type: UPDATE_USER_PENDING
  });

  try {
    const { data } = await request.post("/more-info", keysToSnakeCase(payload));

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data
    });

    dispatch({
      type: SET_REG_STEPS,
      payload: {
        step3Completed: true
      }
    });
  } catch (err) {
    if (err.response.status >= 400) {
      toast.error(err.response.data.error);
    }

    dispatch({
      type: UPDATE_USER_FAILURE
    });
  }
};

export const login = (credentials: ObjType, redirectTo: Function) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: LOGIN_PENDING
    });

    const { data } = await axios.post(
      `${apiBaseURL}/login`,
      keysToSnakeCase(credentials)
    );

    await localforage.setItem("token", data.access_token);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data.user
    });

    dispatch({
      type: GET_SUB_SUCCESS,
      payload: data.subscription
    });

    redirectTo("/dashboard");
  } catch (err) {
    if (err.response.status >= 400) {
      toast.error(err.response.data.error);
    }

    dispatch({
      type: LOGIN_FAILURE
    });
  }
};

export const confirmEmail = (confirmationData: ObjType) => async (
  dispatch: Function
) => {
  try {
    dispatch({
      type: CONFIRM_EMAIL_PENDING
    });

    const { data } = await axios.post(
      `${apiBaseURL}/confirm-email`,
      keysToSnakeCase(confirmationData)
    );

    await localforage.setItem("token", data.access_token);

    dispatch({
      type: CONFIRM_EMAIL_SUCCESS
    });

    dispatch({
      type: SET_REG_STEPS,
      payload: {
        step2Completed: true
      }
    });
  } catch (err) {
    if (err.response.status >= 400) {
      toast.error(err.response.data.error);
    }

    dispatch({
      type: CONFIRM_EMAIL_FAILURE
    });
  }
};
