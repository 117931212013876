import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { StyledFooter, Div, Segment, Icons, Copyright } from "./styles";
import PageContainer from "../../shared/PageContainer";
import Spacer from "../../shared/Spacer";
import { ReactComponent as Facebook } from "../../../svgs/facebook.svg";
import { ReactComponent as Instagram } from "../../../svgs/instagram.svg";
import { ReactComponent as YouTube } from "../../../svgs/youtube.svg";
import { ReactComponent as Twitter } from "../../../svgs/twitter.svg";
import { ReactComponent as Mixcloud } from "../../../svgs/mixcloud.svg";
import { getFooter } from "../../../redux/actions/footer";

const Footer: React.FC<{ getFooter: Function }> = ({ getFooter }) => {
  useEffect(() => {
    getFooter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const footer = useSelector(({ footer }: any) => footer.data);

  return (
    <>
      <StyledFooter>
        <PageContainer>
          {footer && (
            <Div>
              <Segment>
                <p className="title">Our Mission</p>
                <p className="text">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: footer[0].footer_our_mission_text
                    }}
                  ></span>
                </p>
              </Segment>
              <Segment className="footerBg">
                <p className="title">Contact Us</p>

                <p className="subtitle">e-mail</p>
                <p className="text">{footer[0].contact_information.email}</p>
                <Spacer height={2} />
                <p className="subtitle">address</p>
                <p
                  className="text"
                  dangerouslySetInnerHTML={{
                    __html: footer[0].contact_information.address
                  }}
                ></p>
              </Segment>
              <Segment className="footerBg">
                <p className="title">Quick Links</p>
                {footer["menu"] &&
                  footer["menu"]
                    .filter(
                      (menu: any) =>
                        menu.link !==
                        "https://royalafricansociety.org/contact-us/"
                    )
                    .map((menu: any) => (
                      <a className="link" href={menu.link} key={menu.link}>
                        {menu.title}
                      </a>
                    ))}

                <a className="link" href="http://asauk.net/about-us/contact/">
                  CONTACT US
                </a>
              </Segment>
              <Segment className="socialBg">
                <p className="title">Follow Us</p>

                <Icons>
                  <div>
                    <a
                      href={footer[0].social_information.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Twitter height="24" viewBox="10 10 28 24" />
                    </a>
                    <a
                      href={footer[0].social_information.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Facebook />
                    </a>
                    <a
                      href={footer[0].social_information.instagram}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Instagram />
                    </a>
                    <Spacer height={3.1} />
                    <a
                      href={footer[0].social_information.mixcloud}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Mixcloud />
                    </a>
                    <a
                      href={footer[0].social_information.youtube}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <YouTube />
                    </a>
                  </div>
                </Icons>
              </Segment>
            </Div>
          )}
        </PageContainer>
      </StyledFooter>
      <Copyright>
        <div className="copyrights">
          <span>&copy; 2019 ASA UK (RAS) - All rights reserved.</span>
          <div className="footerlinks">
            {footer &&
              footer["footermenu"] &&
              footer["footermenu"].map((menu: any) => (
                <span key={menu.link}>
                  <a href={menu.link}>{menu.title.toUpperCase()}</a>
                </span>
              ))}
          </div>
        </div>
        <div>
          Designed by{" "}
          <a href="http://studio14online.co.uk/" rel="no-follow" target="_bank">
            Studio 14
          </a>
        </div>
      </Copyright>
    </>
  );
};

export default connect(null, { getFooter })(Footer);
