import React, { useState, useMemo } from "react";
import { Route } from "react-router-dom";
import { PrimaryNav, Footer, SecondaryNav } from "../shared";
import Step1 from "./Step1";
import Context from "./Context";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { PaymentRoute, ThankYouRoute } from "./customRoutes";
import { PageTitle } from "../shared/PageTitle";

const Donate: React.FC = () => {
  const [state, setState] = useState({
    amount: 10,
    source: "",
    donationType: "monthly",
    title: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    postCode: "",
    address: "",
    city: "",
    county: "",
    giftAid: false,
    country: "",
    gdpr: false
  });

  const toBeMemoized = {
    ...state,
    setState
  };

  const contextValue = useMemo(() => toBeMemoized, [toBeMemoized]);

  return (
    <>
      <PageTitle
        title="Donate to ASA UK"
        description="Make a donation to the ASA UK"
      />
      <SecondaryNav />
      <PrimaryNav />

      <Context.Provider value={contextValue}>
        <Route path="/donate" component={Step1} exact />
        <PaymentRoute path="/donate/pay" component={Step2} exact />
        <ThankYouRoute path="/donate/thank-you" component={Step3} />
      </Context.Provider>

      <Footer />
    </>
  );
};

export default Donate;
