export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";
export const REGISTER_PENDING = "REGISTER_PENDING";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGOUT = "LOGOUT";

export const CONFIRM_EMAIL_SUCCESS = "CONFIRM_EMAIL_SUCCESS";
export const CONFIRM_EMAIL_PENDING = "CONFIRM_EMAIL_PENDING";
export const CONFIRM_EMAIL_FAILURE = "CONFIRM_EMAIL_FAILURE";

export const DONATE_SUCCESS = "DONATE_SUCCESS";
export const DONATE_PENDING = "DONATE_PENDING";
export const DONATE_FAILURE = "DONATE_FAILURE";

export const GET_ADDRESSES_SUCCESS = "GET_ADDRESSES_SUCCESS";
export const GET_ADDRESSES_PENDING = "GET_ADDRESSES_PENDING";
export const GET_ADDRESSES_FAILURE = "GET_ADDRESSES_FAILURE";

export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_FAILURE = "SUBSCRIBE_FAILURE";
export const SUBSCRIBE_PENDING = "SUBSCRIBE_PENDING";

export const FIND_ADDRESSES_SUCCESS = "FIND_ADDRESSES_SUCCESS";
export const FIND_ADDRESSES_PENDING = "FIND_ADDRESSES_PENDING";
export const FIND_ADDRESSES_FAILURE = "FIND_ADDRESSES_FAILURE";

export const UPGRADE_PLAN_SUCCESS = "UPGRADE_PLAN_SUCCESS";
export const UPGRADE_PLAN_PENDING = "UPGRADE_PLAN_PENDING";
export const UPGRADE_PLAN_FAILURE = "UPGRADE_PLAN_FAILURE";

export const CANCEL_SUB_PENDING = "CANCEL_SUB_PENDING";
export const CANCEL_SUB_FAILURE = "CANCEL_SUB_FAILURE";
export const CANCEL_SUB_SUCCESS = "CANCEL_SUB_SUCCESS";

export const GET_SUB_SUCCESS = "GET_SUB_SUCCESS";
export const GET_SUB_PENDING = "GET_SUB_PENDING";
export const GET_SUB_FAILURE = "GET_SUB_FAILURE";

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_PENDING = "UPDATE_USER_PENDING";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_PENDING = "CHANGE_PASSWORD_PENDING";
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE";

export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_PENDING = "GET_EVENTS_PENDING";
export const GET_EVENTS_FAILURE = "GET_EVENTS_FAILURE";

export const GET_MORE_EVENTS = "GET_MORE_EVENTS";

export const GET_SINGLE_EVENT_SUCCESS = "GET_SINGLE_EVENT_SUCCESS";
export const GET_SINGLE_EVENT_PENDING = "GET_SINGLE_EVENT_PENDING";
export const GET_SINGLE_EVENT_FAILURE = "GET_SINGLE_EVENT_FAILURE";

export const GET_ALL_NEWS_SUCCESS = "GET_ALL_NEWS_SUCCESS";
export const GET_ALL_NEWS_PENDING = "GET_ALL_NEWS_PENDING";
export const GET_ALL_NEWS_FAILURE = "GET_ALL_NEWS_FAILURE";

export const GET_MORE_NEWS = "GET_MORE_NEWS";

export const GET_SINGLE_NEWS_SUCCESS = "GET_SINGLE_NEWS_SUCCESS";
export const GET_SINGLE_NEWS_PENDING = "GET_SINGLE_NEWS_PENDING";
export const GET_SINGLE_NEWS_FAILURE = "GET_SINGLE_NEWS_FAILURE";

export const GET_DISCOUNTS_SUCCESS = "GET_DISCOUNTS_SUCCESS";
export const GET_DISCOUNTS_PENDING = "GET_DISCOUNTS_PENDING";
export const GET_DISCOUNTS_FAILURE = "GET_DISCOUNTS_FAILURE";

export const GET_MORE_DISCOUNTS = "GET_MORE_DISCOUNTS";

export const GET_SINGLE_DISCOUNT_SUCCESS = "GET_SINGLE_DISCOUNT_SUCCESS";
export const GET_SINGLE_DISCOUNT_PENDING = "GET_SINGLE_DISCOUNT_PENDING";
export const GET_SINGLE_DISCOUNT_FAILURE = "GET_SINGLE_DISCOUNT_FAILURE";

export const SET_REG_STEPS = "SET_REG_STEPS";

export const CREATE_PLAN_SUCCESS = "CREATE_PLAN_SUCCESS";
export const CREATE_PLAN_FAILURE = "CREATE_PLAN_FAILURE";
export const CREATE_PLAN_PENDING = "CREATE_PLAN_PENDING";

export const GET_NEWSLETTERS_SUCCESS = "GET_NEWSLETTERS_SUCCESS";
export const GET_NEWSLETTERS_FAILURE = "GET_NEWSLETTERS_FAILURE";
export const GET_NEWSLETTERS_PENDING = "GET_NEWSLETTERS_PENDING";

export const SUBSCRIBE_NEWSLETTER_SUCCESS = "SUBSCRIBE_NEWSLETTER_SUCCESS";
export const SUBSCRIBE_NEWSLETTER_PENDING = "SUBSCRIBE_NEWSLETTER_PENDING";
export const SUBSCRIBE_NEWSLETTER_FAILURE = "SUBSCRIBE_NEWSLETTER_FAILURE";

export const CHANGE_CARD_SUCCESS = "CHANGE_CARD_SUCCESS";
export const CHANGE_CARD_PENDING = "CHANGE_CARD_PENDING";
export const CHANGE_CARD_FAILURE = "CHANGE_CARD_FAILURE";

export const UPDATE_PERSONAL_INFO_SUCCESS = "UPDATE_PERSONAL_INFO_SUCCESS";
export const UPDATE_PERSONAL_INFO_PENDING = "UPDATE_PERSONAL_INFO_PENDING";
export const UPDATE_PERSONAL_INFO_FAILURE = "UPDATE_PERSONAL_INFO_FAILURE";

export const GET_FOOTER_SUCCESS = "GET_FOOTER_SUCCESS";
export const GET_FOOTER_PENDING = "GET_FOOTER_PENDING";
export const GET_FOOTER_FAILURE = "GET_FOOTER_FAILURE";

export const GET_BOTTOM_FOOTER_SUCCESS = "GET_BOTTOM_FOOTER_SUCCESS";
export const GET_BOTTOM_FOOTER_PENDING = "GET_BOTTOM_FOOTER_PENDING";
export const GET_BOTTOM_FOOTER_FAILURE = "GET_BOTTOM_FOOTER_FAILURE";

export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_PENDING = "GET_FAQ_PENDING";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const RENEW_PLAN_SUCCESS = "RENEW_PLAN_SUCCESS";
export const RENEW_PLAN_PENDING = "RENEW_PLAN_PENDING";
export const RENEW_PLAN_FAILURE = "RENEW_PLAN_FAILURE";

export const UPDATE_SUB = "UPDATE_SUB";

export const CHANGE_PAYMENT_METHOD_SUCCESS = "CHANGE_PAYMENT_METHOD_SUCCESS";
export const CHANGE_PAYMENT_METHOD_PENDING = "CHANGE_PAYMENT_METHOD_PENDING";
export const CHANGE_PAYMENT_METHOD_FAILURE = "CHANGE_PAYMENT_METHOD_FAILURE";

export const GET_SUBSCRIBERS_SUCCESS = "GET_SUBSCRIBERS_SUCCESS";
export const GET_SUBSCRIBERS_PENDING = "GET_SUBSCRIBERS_PENDING";
export const GET_SUBSCRIBERS_FAILURE = "GET_SUBSCRIBERS_FAILURE";

export const GET_SINGLE_SUBSCRIBER_FAILURE = "GET_SINGLE_SUBSCRIBER_FAILURE";
export const GET_SINGLE_SUBSCRIBER_PENDING = "GET_SINGLE_SUBSCRIBER_PENDING";
export const GET_SINGLE_SUBSCRIBER_SUCCESS = "GET_SINGLE_SUBSCRIBER_SUCCESS";

export const ADMIN_LOGIN_SUCCESS = "ADMIN_LOGIN_SUCCESS";
export const ADMIN_LOGIN_FAILURE = "ADMIN_LOGIN_FAILURE";
export const ADMIN_LOGIN_PENDING = "ADMIN_LOGIN_PENDING";

export const GET_SUBSCRIBERS = "GET_SUBSCRIBERS";
export const GET_SUBSCRIBER = "GET_SUBSCRIBER";
export const CREATE_SUBSCRIBER = "CREATE_SUBSCRIBER";

export const GET_GIFTS = "GET_GIFTS";
export const GET_GIFT = "GET_GIFT";

export const GET_DONATIONS = "GET_DONATIONS";
export const GET_DONATION = "GET_DONATION";

export const DEACTIVATE_SUBSCRIBER = "DEACTIVATE_SUBSCRIBER";
export const ACTIVATE_SUBSCRIBER = "ACTIVATE_SUBSCRIBER";

export const CHANGE_ADMIN_PASSWORD = "CHANGE_ADMIN_PASSWORD";
