import React, { Component, Fragment } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import changeCard, {
  changePaymentMethod
} from "../../../redux/actions/changeCard";
import { CreditCardContainer } from "./styles";
import { Button, Spacer, CenterBox } from "../../shared";
import supportPaymentOptions from "../../../svgs/supported-cards.svg";

interface Props extends RouteComponentProps {
  [key: string]: any;
}

class CheckoutForm extends Component<Props> {
  state = {
    loading: false
  };

  submit = async () => {
    const { userStore, subscription } = this.props;
    const user = userStore.data;
    const name = `${user.first_name} ${user.last_name}`;

    this.setState({
      loading: true
    });

    const { token } = await this.props.stripe.createToken({ name });

    const paymentSource = token.id;
    const card = token.card.last4;

    if (subscription.subscription_provider !== "stripe") {
      this.props.changePaymentMethod(
        {
          paymentSource,
          card,
          paymentMethod: "stripe"
        },
        this.props.toggleModal
      );
    } else {
      await this.props.changeCard(
        { paymentSource, card },
        this.props.toggleModal
      );
    }

    this.setState({
      loading: false
    });
  };

  render() {
    return (
      <Fragment>
        <CreditCardContainer>
          <CardElement
            style={{
              base: {
                fontSize: "14px",
                "::placeholder": {
                  color: "#bdbdbd"
                }
              }
            }}
          />
        </CreditCardContainer>

        <Spacer height={3.1} />

        <CenterBox>
          <Button
            type="button"
            large
            onClick={() => this.submit()}
            disabled={this.state.loading || this.props.userStore.loading}
          >
            {this.state.loading ? (
              <Loader type="Oval" color="#ffffff" height={20} width={20} />
            ) : (
              "Add Card"
            )}
          </Button>
        </CenterBox>

        <Spacer height={5.7} />

        <CenterBox>
          <img src={supportPaymentOptions} alt="Support payment options" />
        </CenterBox>
      </Fragment>
    );
  }
}

const TransformedCheckoutForm = injectStripe(withRouter(CheckoutForm));

const mapStateToProps = ({ user, subscription }: any) => ({
  userStore: user,
  subscription: subscription.data
});

export default connect(mapStateToProps, { changeCard, changePaymentMethod })(
  TransformedCheckoutForm
);
