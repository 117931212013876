import React from "react";
import { ThemeProvider } from "styled-components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-activity/dist/react-activity.css";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import theme from "./styles/theme";
import Routes from "./Routes";
import GlobalStyle from "./styles/GlobalStyle";
import storage from "./redux/store";
import ErrorBoundary from "./ErrorBoundary";
import { PageLoader } from "./components/shared";

const { store, persistor } = storage();

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <GlobalStyle />

          <ToastContainer
            style={{
              fontSize: "1.6rem"
            }}
            closeButton={false}
            autoClose={2500}
            hideProgressBar={true}
          />
          <PersistGate loading={<PageLoader />} persistor={persistor}>
            <Helmet>
              <meta charSet="utf-8" />
              <title>African Studies Association of the UK (ASAUK)</title>
              <link rel="canonical" href="" />
              <meta
                name="description"
                content="We are the African Studies Association of the UK (ASAUK)"
              />
            </Helmet>
            <Routes />
          </PersistGate>
        </Provider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;
