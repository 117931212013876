import React, { useContext } from "react";
import { Formik } from "formik";
import { string, object } from "yup";
import Loader from "react-loader-spinner";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { Footer, Input, InputWrapper as FieldWrapper } from "../../shared";
import { AdminPrimaryNav } from "../Dashboard/shared";
import { Form, H1, Separator, FormGroup, LoginBtn } from "../../SignIn/styles";
import formHasErrors from "../../../utils/formHasErrors";
import { useLogin, useRedirect } from "./hooks";
import { PageTitle } from "../../shared/PageTitle";

const validationSchema = object().shape({
  email: string()
    .email("Invalid email provided.")
    .required("No email provided."),
  password: string().required("No password provided.")
});

const SignIn: React.FC<RouteComponentProps> = ({ history }) => {
  const theme = useContext(ThemeContext);
  const [adminState, adminLogin]: any = useLogin();
  useRedirect(history.push);

  return (
    <>
      <PageTitle
        title="Sign in to ASA UK Admin"
        description="Sign in to ASA UK"
      />
      <AdminPrimaryNav />
      <Formik
        onSubmit={credentials =>
          adminLogin(credentials, () => history.push("/admin/dashboard"))
        }
        initialValues={{ email: "", password: "" }}
        validationSchema={validationSchema}
        validateOnChange={true}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          dirty
        }) => (
          <Form onSubmit={handleSubmit}>
            <H1>Sign in to ASA UK Admin</H1>

            <Separator>
              <hr />
            </Separator>

            <FormGroup>
              <InputWrapper>
                <Input
                  errored={Boolean(touched.email && errors.email)}
                  placeholder="Username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  name="email"
                  value={values.email}
                />

                <span className="error">{touched.email && errors.email}</span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <InputWrapper>
                <Input
                  placeholder="Password"
                  errored={Boolean(touched.password && errors.password)}
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />

                <span className="error">
                  {touched.password && errors.password}
                </span>
              </InputWrapper>
            </FormGroup>

            <FormGroup>
              <LoginBtn
                type="submit"
                secondary
                large
                disabled={formHasErrors(errors) || adminState.loading || !dirty}
              >
                {adminState.loading && (
                  <Loader
                    type="Oval"
                    color={theme.white}
                    height={20}
                    width={20}
                  />
                )}
                {!adminState.loading && "Log In"}
              </LoginBtn>
            </FormGroup>

            {/* <Link to="/reset-password">Forgot your password?</Link> */}
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
};

const InputWrapper = styled(FieldWrapper)`
  width: 100%;
`;

export default SignIn;
