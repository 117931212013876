import React from "react";
import { Link } from "react-router-dom";
import { Spacer, Steps } from "../shared";
import {
  Container,
  H1,
  P,
  StepsWrapper,
  Plans,
  Plan,
  BtnCont,
  CTABtn
} from "./upgradePlan/styles";
import { prices, plans } from "./upgradePlan/metaData";
import { PageTitle } from "../shared/PageTitle";

const UpgradeSubscription: React.FC = () => {
  return (
    <>
      <PageTitle
        title="Renew your membership"
        description="Renew your membership, Choose the plan that is right for you on the ASA UK"
      />
      <Container>
        <Spacer height={11} />

        <H1>Renew your membership</H1>

        <Spacer height={1.3} />

        <P>Choose the plan that is right for you</P>

        <Spacer height={5.8} />

        <StepsWrapper>
          <Steps maxSteps={2} step={1} />
        </StepsWrapper>

        <Plans>
          {plans.map(plan => (
            <Plan key={plan}>
              <p className="title">{plan}</p>

              <Spacer height={4.4} />
              <p className="price">£{prices[plan].price}</p>

              <p className="description">{prices[plan].desc}</p>

              <Spacer height={2.5} />

              <BtnCont>
                <CTABtn
                  as={Link}
                  to={`/dashboard/account/renew-plan/pay?plan=${plan}`}
                >
                  Select {plan}
                </CTABtn>
              </BtnCont>
            </Plan>
          ))}
        </Plans>

        <Spacer height={11.4} />
      </Container>
    </>
  );
};

export default UpgradeSubscription;
