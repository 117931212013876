import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { PrimaryNav, Footer, SecondaryNav } from "../shared";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { parseQueryString } from "../../utils/queryString";
import hardRedirectTo from "../../utils/hardRedirectTo";
import { PageTitle } from "../shared/PageTitle";

const SignUp: React.FC<RouteComponentProps> = props => {
  const { plan } = parseQueryString(props.location.search);

  useEffect(() => {
    if (!plan) {
      hardRedirectTo("http://www.asauk.net/membership/");
    }
  }, [plan]);

  const {
    step1Completed,
    step2Completed,
    step3Completed,
    step4Completed
  } = useSelector((state: any) => state.registrationSteps);

  if (!plan) {
    hardRedirectTo("http://www.asauk.net/membership/");

    return <React.Fragment />;
  }

  const renderComponent = () => {
    if (!step1Completed) {
      return <Step1 {...props} />;
    }

    if (!step2Completed) {
      return <Step2 {...props} />;
    }

    if (!step3Completed) {
      return <Step3 {...props} />;
    }

    if (!step4Completed) {
      return <Step4 {...props} />;
    }
  };

  return (
    <>
      <PageTitle
        title={`Sign up to become an ASA UK member (${plan
          .charAt(0)
          .toUpperCase() + plan.slice(1)} Plan)`}
        description={`Sign up to become a ASA UK member (${plan
          .charAt(0)
          .toUpperCase() + plan.slice(1)} Plan) - ASA UK`}
      />
      <SecondaryNav />
      <PrimaryNav />

      {renderComponent()}
      <Footer />
    </>
  );
};

export default SignUp;
