import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter, RouteComponentProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReactComponent as CaretRight } from "../../../svgs/caret-right.svg";
import {
  Spacer,
  GhostButton,
  CenterBox,
  EventContent,
  PageLoader,
} from "../../shared";
import { getEvents, getMoreEvents } from "../../../redux/actions/event";
import { ViewMore, EventCard, EventsGrid } from "./styles";
import useLoading from "../../../hooks/useLoading";

interface Props extends RouteComponentProps {
  getEvents: Function;
  getMoreEvents: Function;
  controls: any;
}

const GridView: React.FC<Props> = React.memo(
  ({ getEvents, controls, getMoreEvents, history }) => {
    const [isLoading, load] = useLoading();
    const perPage = 8;

    useEffect(() => {
      load(getEvents(controls));

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controls]);

    const [viewMoreControls, setViewMoreControls] = useState(controls);

    useEffect(() => {
      setViewMoreControls(controls);
    }, [controls]);

    const events = useSelector(({ events }: any) => events.data);

    return (
      <>
        <EventsGrid>
          {isLoading ? (
            <PageLoader />
          ) : (
            events.data.map((event: any) => (
              <EventCard
                key={event.id}
                onClick={() => history.push(`/dashboard/events/${event.slug}`)}
              >
                <EventContent featuredImage={event.featured_image}>
                  <div className="header">
                    <span>When- {event.events_date}</span>
                    <span>{event.category && event.category.name}</span>
                  </div>

                  <Link
                    className="title"
                    to={`/dashboard/events/${event.slug}`}
                  >
                    <span title={event.post_title}>
                      {event.post_title.length < 35
                        ? event.post_title
                        : event.post_title.substr(0, 35) + "..."}
                    </span>
                  </Link>

                  <div>
                    <GhostButton
                      as={Link}
                      to={`/dashboard/events/${event.slug}`}
                    >
                      Read more
                    </GhostButton>
                  </div>
                </EventContent>
              </EventCard>
            ))
          )}
        </EventsGrid>

        <Spacer height={8.3} />

        {!isLoading && viewMoreControls.page < events.pageCount && (
          <CenterBox>
            <ViewMore
              onClick={() => {
                const newControls = {
                  ...viewMoreControls,
                  page: viewMoreControls.page + 1,
                  perPage,
                };

                setViewMoreControls(newControls);

                getMoreEvents(newControls);
              }}
            >
              View more Events
              <CaretRight />
            </ViewMore>
          </CenterBox>
        )}
      </>
    );
  }
);

export default connect(
  null,
  {
    getEvents,
    getMoreEvents,
  }
)(withRouter(GridView));
