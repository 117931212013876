import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Donate from "../components/Donation";
import SignIn from "../components/SignIn";
import Admin from "../components/Admin/SignIn";
import AdminDashboard from "../components/Admin/Dashboard";
import SignUp from "../components/SignUp";
import Dashboard from "../components/Dashboard";
import AuthedRoute from "./AuthedRoute";
import ResetPassword from "../components/ResetPassword";
import Gifting from "../components/Gifting";
import AdminGate from "./AdminGate";
import ScrollToTop from "./ScrollToTop";

const Routes: React.FC = () => (
  <Router>
    <ScrollToTop />
    <Switch>
      <Redirect from="/" to="/login" exact />
      <AuthedRoute path="/dashboard" component={Dashboard} />
      <Route path="/login" component={SignIn} />
      <Route path="/sign-up" component={SignUp} exact />
      <Route path="/donate" component={Donate} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/admin" component={Admin} exact />
      <AdminGate path="/admin/dashboard" component={AdminDashboard} />
      <Route path="/gifting" component={Gifting} />
    </Switch>
  </Router>
);

export default Routes;
