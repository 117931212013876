import React, { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { PrimaryNav, Footer, SecondaryNav } from "../shared";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import { parseQueryString } from "../../utils/queryString";
import { PageTitle } from "../shared/PageTitle";

const Gifting: React.FC<RouteComponentProps> = ({ location }) => {
  const [state, setState] = useState({
    title: "",
    firstName: "",
    lastName: "",
    mobile: "",
    email: "",
    postCode: "",
    genderIdentity: "",
    address: "",
    city: "",
    county: "",
    byPost: false,
    country: "",
    gdpr: false
  });
  const [step, setStep] = useState(1);
  const { plan } = parseQueryString(location.search);

  const goNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const steps: { [key: string]: JSX.Element } = {
    step1: <Step1 state={state} goNext={goNext} setState={setState} />,
    step2: <Step2 state={state} plan={plan} goNext={goNext} />,
    step3: <Step3 state={state} plan={plan} />
  };

  return (
    <>
      <PageTitle title="Gifting" description="Give a ASA UK Membership Gift" />
      <SecondaryNav />
      <PrimaryNav />
      {steps[`step${step}`]}
      <Footer />
    </>
  );
};

export default Gifting;
