import styled, { css } from "styled-components";
import { LinkProps } from "react-router-dom";
import { LocationDescriptor } from "history";
import { Button } from "../shared";

export const H1 = styled.h1`
  font-weight: 400;
  margin-left: 0;
  font-size: 4.1rem;
  line-height: 4.4rem;
`;

export const H3 = styled.h3`
  font-weight: 300;
  font-size: 2.5rem;
`;

export const HR = styled.hr`
  border: none;
  background: ${({ theme }) => theme.orange};
  height: 0.5rem;
  width: 6.01rem;
  margin-top: 4rem;
`;

export const Card = styled.div`
  border-radius: 15px;
  color: ${({ theme }) => theme.white};
`;

export const FirstCardContent = styled.div`
  display: flex;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.orange};
  padding: 8.1rem 4.9rem 6.6rem;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 3.6rem;
    line-height: 107.5%;
    font-weight: 400;
  }
`;

interface Props {
  secondary?: boolean;
  tertiary?: boolean;
}
export const AdminCardContent = styled.div<Props>`
  display: flex;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background: ${({ theme }) => theme.orange};
  padding: 8.1rem 4.9rem 6.6rem;
  flex-direction: column;
  justify-content: space-between;

  .title {
    font-size: 3.6rem;
    line-height: 107.5%;
    font-weight: 400;
  }

  ${({ secondary, theme }) =>
    secondary &&
    css`
      background: ${theme.deepGreen};

      .events {
        color: ${theme.white};

        &:hover {
          color: ${theme.orange} !important;
        }
      }
    `}

  ${({ tertiary, theme }) =>
    tertiary &&
    css`
      background: ${theme.skyBlue};

      .events {
        color: ${theme.white};

        &:hover {
          color: ${theme.black} !important;
        }
      }
    `}
`;

export const EventContent = styled.div`
  word-wrap: break-word;
  display: flex;
  border-radius: 15px;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.53), rgba(0, 0, 0, 0.53)),
    url("https://images.unsplash.com/photo-1509099863731-ef4bff19e808?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=500&q=60");
  background-size: cover;
  padding: 5.6rem 4.9rem 6.2rem;

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1.4rem;
    line-height: 107.5%;
    font-weight: 300;
  }

  .title {
    font-size: 3.6rem;
    line-height: 107.5%;
    margin-bottom: 2.2rem;
    margin-top: 2.6rem;
    width: 70%;
  }
`;

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(34.2rem, 1fr));
  grid-auto-rows: 39.16rem;
  gap: 1rem 1rem;
  grid-template-rows: repeat(auto-fill, 39.16rem);
`;

export const NewsGrid = styled.div`
  width: 100%;
  display: flex;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
`;

export const NewsTitle = styled.div`
  color: ${({ theme }) => theme.white};
  max-width: 39.164rem;
  width: 100%;
  margin-right: 1.336rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 7.65rem;
    margin-top: 3.825rem;
  }

  @media screen and (max-width: 1000px) {
    max-width: 100%;
    margin-right: 0;
  }

  div {
    background: ${({ theme }) => theme.deepGreen};
    border-radius: 15px;
    height: 23.4rem;
    padding: 4.6rem 4.9rem 6rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .discounts {
    background: ${({ theme }) => theme.skyBlue};
  }

  .title {
    font-size: 3.6rem;
    line-height: 107.5%;
  }
`;

export const NewsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(23.4rem, 1fr));
  gap: 2.2rem 2.2rem;

  &:last-child {
    grid-column: span 2;
  }

  .discount-bg {
    margin-bottom: 8.5rem;
  }
`;

export const IndividualNewsContainer = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
  }

  .show-mobile {
    display: none;

    @media screen and (max-width: 992px) {
      display: block;
      margin-bottom: 30px;
    }
  }

  .show-desktop {
    display: none;

    @media screen and (min-width: 992px) {
      display: block;
    }
  }

  .newsContent {
    width: 100%;
    max-width: 24.4rem;
    height: auto;
    margin-right: 2.2rem;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }

  .slider-control-bottomcenter {
    width: 100% !important;
    text-align: center !important;
    margin-bottom: -32px;

    ul {
      top: 0 !important;

      .active {
        .paging-dot {
          background: ${({ theme }) => theme.orange} !important;
        }
      }
      li {
        margin-right: 5px;

        button:focus {
          outline: none;
        }

        .paging-dot {
          border-radius: 0 !important;
          width: 1rem !important;
          height: 1rem !important;
          background: ${({ theme }) => theme.gray3} !important;
        }
      }
    }
  }
`;

export const EventCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.white};
  max-width: 37.5rem;
  min-height: 34.2rem;
  margin-right: 0.9rem;
  cursor: pointer;

  @media screen and (max-width: 992px) {
    margin-bottom: 7.65rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .subscribersContent {
    min-height: 26.2rem;
  }

  .eventsContent {
    min-height: 34.2rem;
  }

  &:first-child {
    max-width: 39.2rem;

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }

  &:last-child {
    margin-right: 0%;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    max-width: 100%;
  }

  .newsContent {
    width: 100%;
    max-width: 24.4rem;
    height: auto;
    margin-bottom: 7.65rem;

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }
`;

export const SubscribersCard = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  color: ${({ theme }) => theme.white};
  min-height: 26.2rem;
  margin-bottom: 5.8rem;
  margin-right: 0.9rem;

  .subscribersContent {
    min-height: 26.2rem;
  }

  .eventsContent {
    min-height: 34.2rem;
  }

  &:first-child {
    max-width: 37.4rem;

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }

  &:last-child {
    margin-right: 0%;
  }

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    max-width: 100%;
  }

  .newsContent {
    width: 100%;
    max-width: 24.4rem;
    height: auto;
    margin-bottom: 7.65rem;

    @media screen and (max-width: 1000px) {
      max-width: 100%;
    }
  }
`;

export const NewsContent = styled.div<{ featuredImage: string }>`
  height: 39rem;
  display: flex;
  flex-direction: column;
  border-radius: 1.9rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 7.65rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    cursor: pointer;
  }

  figure {
    height: 23.4rem;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
      border-radius: 1.9rem;
    }
  }

  .headimage {
    width: 100%;
    height: 23.4rem;
    background-size: cover;
    border-radius: 1.9rem;
    ${({ featuredImage }) => css`
    background-image: url('${featuredImage}');
    background-position: center;
    background-size: cover;
    `}

    div {
      height: 100%;

      :hover {
        border-radius: 1.9rem;
        background-color: rgba(1, 1, 1, 0.71); 
      }
    }
  }

  .headline {
    margin-top: 2.5rem;
    display: block;
    line-height: 2.2rem;
    padding-bottom: 2.85rem;
    font-size: 1.8rem;
    text-decoration: none;
    min-height: 7.35rem;

    ${({ theme }) => css`
      color: ${theme.deepGreen};
    `};

    &:hover {
      color: ${({ theme }) => theme.orange};
    }
  }

  p.headline {
    padding-bottom: 1.6rem;
    cursor: pointer;
    outline: none;
    border: none;
    text-align: left;
    border-bottom: 1px solid ${({ theme }) => theme.gray3};
  }

  .source {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.gray};
    margin-top: 1.6rem;
    line-height: 1.7rem;
  }

  .view-deal {
    color: ${({ theme }) => theme.orange};
    font-size: 1.4rem;
    margin-top: 1.6rem;
    text-align: left;
    background: transparent;
    border: none;
    outline: transparent;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.deepGreen};
    }
  }
`;

interface A extends LinkProps {
  href?: string;
  to: LocationDescriptor<any>;
}

export const OUPLink = styled.a<A>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 19.3rem;
  font-size: 6.4rem;
  line-height: 7.7rem;
  text-decoration: none;
  position: relative;
  padding: 1.5rem;

  @media screen and (max-width: 992px) {
    font-size: 3.5rem;
    line-height: normal;
    text-align: center;
    display: flex;
    flex-direction: column;

    img {
      position: initial !important;
      padding-bottom: 6px;
    }
  }

  ${({ theme }) => css`
    background: ${theme.orange2};
    color: ${theme.white};
  `}

  img {
    position: absolute;
    bottom: 2.8rem;
    right: 4.2rem;
  }
`;

export const TabsContainer = styled.div`
  li {
    display: inline-block;
    padding: 0 2rem;
    cursor: pointer;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid transparent;

    @media screen and (max-width: 992px) {
      padding: 2rem 2rem;
    }
  }

  .tabs {
    padding-left: 7rem;

    @media screen and (max-width: 992px) {
      padding-left: 0;
      text-align: center;
    }
  }

  .react-tabs__tab--selected {
    border-bottom: 1px solid ${({ theme }) => theme.orange};
  }

  .react-tabs {
  }

  .react-tabs__tab-list {
    border-bottom: 1px solid ${({ theme }) => theme.gray4};
    text-transform: uppercase;
    font-size: 1.4rem;
  }
`;

export const Form = styled.form`
  max-width: 41rem;
  margin: 0 auto;
  text-align: center;

  h2 {
    font-size: 3.6rem;
    line-height: 120%;
    font-weight: 300;
  }
`;

export const BillingContainer = styled.div`
  text-align: center;

  .title {
    color: ${({ theme }) => theme.orange};
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 3.2rem;
  }

  .panel {
    display: inline-flex;
    height: 4.6rem;
    width: 27.5rem;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-size: 1.8rem;
    margin-bottom: 2rem;

    ${({ theme }) => css`
      background: ${theme.offWhite2};
      border: 1px solid ${theme.gray2};
    `}
  }

  .expires-on {
    color: ${({ theme }) => theme.gray};
    font-size: 1.3rem;
    margin-bottom: 1.6rem;
  }

  .cancel-plan {
    color: ${({ theme }) => theme.greenishBlack};
    text-transform: uppercase;
    border: none;
    cursor: pointer;
    background: transparent;
    font-size: 1.2rem;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .name {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
  }

  .card-details {
    color: ${({ theme }) => theme.gray3};
    font-size: 1.6rem;
    margin-bottom: 3.2rem;
  }
`;

export const ProfileForm = styled(Form)`
  max-width: 80.5rem;
`;

export const GoBack = styled.div`
  text-align: right;
  margin-top: -1.7rem;

  a {
    font-size: 1.8rem;
    line-height: 150%;
    color: ${({ theme }) => theme.orange};
    text-decoration: none;

    svg {
      margin-right: 1rem;
    }
  }
`;

export const ModalWrapper = styled.div`
  padding: 0 4rem;
  font-size: 1.8rem;
  font-weight: 300;

  a {
    color: ${({ theme }) => theme.orange};
  }

  h1 {
    font-size: 3rem;
    font-weight: 300;

    @media screen and (max-width: 992px) {
      font-size: 2.5rem;
    }
  }

  .text {
    margin-top: -1.8rem;
    line-height: 150%;
  }
`;

export const PanelButton = styled(Button)`
  width: 100%;

  svg {
    margin-left: 3rem;

    path {
      stroke: ${({ theme }) => theme.white};
    }
  }

  &:hover {
    path {
      stroke: ${({ theme }) => theme.orange};
    }
  }
`;

export const ModalText = styled.p`
  padding: 0 2rem;
  font-size: 1.8rem;
  line-height: 150%;
  font-weight: 300;
`;

export const SVG = styled.svg`
  stroke: black;
  width: 30px;
  height: 30px;
`;
