import React, { useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Case from "case";
import { connect } from "react-redux";
import { Spacer, Steps, Button, FullPageLoader } from "../../shared";
import { parseQueryString } from "../../../utils/queryString";
import { Container, H1, P, StepsWrapper, Billing } from "./styles";
import { prices } from "./metaData";
import { upgradePlan, renewPlan } from "../../../redux/actions/plan";
import ChangeCard from "../ChangeCard";
import { PageTitle } from "../../shared/PageTitle";
import PaymentDetails from "./PaymentDetails";

interface Props extends RouteComponentProps {
  upgradePlan: Function;
  renewPlan: Function;
}

const GiftCharge: React.FC<Props> = ({ match }) => {
  const location = useLocation();

  const { plan } = parseQueryString(location.search);

  const [modalState, setModalState] = useState(false);
  const [paymentModalState, setPaymentModalState] = useState(false);

  const [loaderState, setLoaderState] = useState(false);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const togglePaymentModal = () => {
    setPaymentModalState(!paymentModalState);
  };

  const isRenew = match.path.includes("renew-plan");

  return (
    <>
      <PageTitle
        title={`Lets finish this up! Upgrade to ${plan.charAt(0).toUpperCase() +
          plan.slice(1)}'s Plan`}
        description={`Lets finish this up! Upgrade to ${plan
          .charAt(0)
          .toUpperCase() + plan.slice(1)} Plan`}
      />
      <Container>
        <Spacer height={11} />

        <H1>Lets finish this up!</H1>

        <Spacer height={1.3} />

        <P>{Case.capital(plan)} Plan</P>

        <Spacer height={5.8} />

        <StepsWrapper>
          <Steps maxSteps={2} step={2} />
        </StepsWrapper>

        <Billing>
          <p className="title">Bill</p>

          <Spacer height={2} />

          <p className="price">
            <sup>£</sup>
            {prices[plan].price}
            <sub>.00</sub>
          </p>

          <Button large type="button" onClick={togglePaymentModal}>
            {isRenew ? "Renew My Plan" : "Pay"}
          </Button>

          <Spacer height={4} />

          <hr />

          <Spacer height={22.3} />
        </Billing>

        <ChangeCard modalActive={modalState} toggleModal={toggleModal} />

        <PaymentDetails
          modalActive={paymentModalState}
          toggleModal={togglePaymentModal}
          plan={plan}
          setLoaderState={setLoaderState}
        />
      </Container>

      {loaderState && <FullPageLoader />}
    </>
  );
};

export default connect(null, {
  upgradePlan,
  renewPlan
})(GiftCharge);
