import React, { useEffect } from "react";
import Case from "case";
import { Link, RouteComponentProps } from "react-router-dom";
import format from "date-fns/format";
import { PageContainer, Spacer, CenterBox, PageLoader } from "../../../shared";
import { PageTitle } from "../../../shared/PageTitle";
import { H1, HR } from "../../../Dashboard/styles";
import { GoBackButton } from "../style";
import { ReactComponent as BackIcon } from "../../../../svgs/caret-left.svg";
import { useGetDonation } from "./hooks";
import useLoading from "../../../../hooks/useLoading";
import Flex from "../shared/Flex";
import { PersonalInforamtionContainer } from "../AllSubscribers/styles";
import { formatToGBP } from "../../../../utils/currency";

const SingleDonatrion: React.FC<RouteComponentProps> = ({
  match: { params }
}) => {
  const [donation, getDonation] = useGetDonation();
  const [isLoading, load] = useLoading();
  const { donationID }: any = params;

  useEffect(() => {
    load(getDonation(donationID));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationID]);

  return (
    <>
      {isLoading && <PageLoader />}

      {!isLoading && donation.data && (
        <>
          <PageContainer>
            <PageTitle
              title={`${donation.data.title} ${donation.data.first_name} ${donation.data.last_name}'s Donation Details`}
              description={`${donation.data.title} ${donation.data.first_name} ${donation.data.last_name}'s Donation Details on ASA UK`}
            />
            <Spacer height={8.9} />
            <H1>
              {`${donation.data.title} ${donation.data.first_name}`} <br />{" "}
              {`${donation.data.last_name}'s Donation Details`}
            </H1>

            <HR />

            <Spacer height={7.4} />
            <PersonalInforamtionContainer>
              <Flex>
                <div>
                  <p className="title">Reference:</p>
                  <div className="panel">{donation.data.reference} </div>
                  <p className="title">Fullname:</p>
                  <div className="panel">
                    {Case.capital(
                      donation.data.title +
                        donation.data.first_name +
                        donation.data.last_name
                    )}{" "}
                  </div>

                  <p className="title">Email:</p>
                  <div className="panel">{donation.data.email} </div>
                  <p className="title">Mobile:</p>
                  <div className="panel">{donation.data.mobile} </div>
                  <p className="title">Donation Amount:</p>
                  <div className="panel">
                    {formatToGBP(donation.data.amount)}{" "}
                  </div>
                  <p className="title">Donation Type:</p>
                  <div className="panel">
                    {Case.capital(donation.data.donation_type)}{" "}
                  </div>
                  <p className="title">Gift Aid:</p>
                  <div className="panel">
                    {donation.data.gift_aid ? "Yes" : "No"}
                  </div>
                </div>
                <div>
                  <p className="title">Post Code:</p>
                  <div className="panel">{donation.data.post_code} </div>
                  <p className="title">Address:</p>
                  <div className="panel">{donation.data.address} </div>

                  <p className="title">City:</p>
                  <div className="panel">{donation.data.city} </div>

                  <p className="title">Area:</p>
                  <div className="panel">{donation.data.county} </div>
                  <p className="title">Nationality:</p>
                  <div className="panel">{donation.data.country} </div>
                  <p className="title">Date:</p>
                  <div className="panel">
                    {" "}
                    {format(donation.data.created_at, "DD/MM/YYYY")}{" "}
                  </div>
                </div>
              </Flex>
            </PersonalInforamtionContainer>
          </PageContainer>
        </>
      )}

      <GoBackButton>
        <CenterBox>
          <Link to="/admin/dashboard/donations">
            <BackIcon />
            Go Back
          </Link>
        </CenterBox>
      </GoBackButton>
    </>
  );
};

export default SingleDonatrion;
