import React from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps
} from "react-router-dom";

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const AuthedRoute: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const user = useSelector((state: any) => state.user.data);

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login"
            }}
          />
        )
      }
    />
  );
};

export default AuthedRoute;
