import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import ReactTable from "react-table";
import "react-table/react-table.css";
import { Formik } from "formik";
import { format } from "date-fns";
import Case from "case";
import {
  PageContainer,
  Spacer,
  PageLoader,
  Modal,
  FormGroup,
  InputWrapper as InputWrap,
  Input,
  Select,
  Button
} from "../../../shared";
import { H1, HR, GoBack } from "../../../Dashboard/styles";
import { ReactComponent as BackIcon } from "../../../../svgs/caret-left.svg";
import { PageTitle } from "../../../shared/PageTitle";
import { useGetDonations } from "./hooks";
import useLoading from "../../../../hooks/useLoading";
import { Header, Column, Form } from "../style";
import { formatToGBP } from "../../../../utils/currency";

const AllDonations = () => {
  const [isLoading, load] = useLoading();
  const [donations, getDonations] = useGetDonations();
  const [modalState, setModalState] = useState(false);

  useEffect(() => {
    load(getDonations());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleModal = () => {
    setModalState(!modalState);
  };

  const columns = [
    {
      Header: () => (
        <Header>
          <h3>Full name</h3>
        </Header>
      ),
      id: "firstName",
      accessor: (d: { d: { [key: string]: string }[] }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/donations/${props.value.id}`}
            className="number"
          >
            {props.value.title +
              " " +
              props.value.first_name +
              " " +
              props.value.last_name}
          </Link>
        </Column>
      )
    },
    {
      Header: () => (
        <Header>
          <h3>Email</h3>
        </Header>
      ),
      id: "email",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/donations/${props.value.id}`}
            className="number"
          >
            {props.value.email}
          </Link>
        </Column>
      )
    },
    {
      Header: () => (
        <Header>
          <h3>Amount</h3>
        </Header>
      ),
      id: "amount",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/donations/${props.value.id}`}
            className="number"
          >
            {formatToGBP(props.value.amount)}
          </Link>
        </Column>
      )
    },
    {
      Header: (props: any) => (
        <Header>
          <h3>Donation Type</h3>
        </Header>
      ),
      id: "email",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/donations/${props.value.id}`}
            className="number"
          >
            {Case.capital(props.value.donation_type)}
          </Link>
        </Column>
      )
    },
    {
      Header: (props: any) => (
        <Header>
          <h3>Date</h3>
        </Header>
      ),
      id: "email",
      accessor: (d: { d: any }) => d,
      Cell: (props: { value: any }) => (
        <Column>
          <Link
            to={`/admin/dashboard/gifts/${props.value.id}`}
            className="number"
          >
            {format(props.value.created_at, "DD/MM/YYYY")}
          </Link>
        </Column>
      )
    }
  ];

  return (
    <>
      <PageTitle
        title="All Donations"
        description="Manage all ASA UK Donations"
      />
      <PageContainer>
        <Spacer height={8.9} />

        <H1>
          All
          <br />
          Donations
        </H1>

        <HR />

        <GoBack>
          <Link to="/admin/dashboard">
            <BackIcon />
            Go Back
          </Link>
        </GoBack>

        <Spacer height={7.5} />

        {isLoading && <PageLoader />}

        {!isLoading && donations.data && (
          <>
            <ReactTable
              data={donations.data}
              columns={columns}
              defaultPageSize={10}
              minRows={1}
              showPagination={true}
              showPaginationTop={false}
              showPaginationBottom={true}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              manual
              pages={donations.last_page}
              previousText="Previous Lists"
              nextText="Next Lists"
              loadingText="Loading Donations.."
              noDataText="No rows found"
              onFetchData={state => {
                getDonations(state.page + 1, state.pageSize);
              }}
            />
          </>
        )}

        <Modal
          title="Create user"
          modalActive={modalState}
          toggleModal={toggleModal}
        >
          <Formik
            enableReinitialize={true}
            initialValues={{}}
            onSubmit={formData => {}}
          >
            {({
              handleBlur,
              handleSubmit,
              values,
              handleChange,
              dirty,
              touched,
              errors
            }) => (
              <Form onSubmit={handleSubmit}>
                <FormGroup>
                  <InputWrapper>
                    <Select
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option>Title *</option>
                      <option value="Sir">Sir</option>
                      <option value="Revd">Revd</option>
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                      <option value="Miss">Miss</option>
                      <option value="Dame">Dame</option>
                      <option value="Baroness">Baroness</option>
                      <option value="Lord">Lord</option>
                      <option value="Earl">Earl</option>
                      <option value="Rt Hon">Rt Hon</option>
                      <option value="Rt Hon Sir">Rt Hon Sir</option>
                    </Select>
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="firstName"
                      // value={values.address}
                      type="text"
                      placeholder="First name *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="lastName"
                      type="text"
                      placeholder="lastName *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="email"
                      type="email"
                      placeholder="Email *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </FormGroup>

                <FormGroup>
                  <InputWrapper>
                    <Input
                      name="workEmail"
                      type="email"
                      placeholder="Work email *"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </InputWrapper>
                </FormGroup>

                <Button type="submit" large>
                  Save
                </Button>
              </Form>
            )}
          </Formik>
        </Modal>

        <Spacer height={10.5} />
      </PageContainer>
    </>
  );
};

const InputWrapper = styled(InputWrap)`
  width: 100%;
`;

export default AllDonations;
