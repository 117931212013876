import React from "react";
import { useSelector } from "react-redux";
import isAfter from "date-fns/is_after";
import { Route, RouteProps, RouteComponentProps } from "react-router-dom";
import Plans from "./Plans";

interface PrivateRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const SubGate: React.FC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const subscription = useSelector((state: any) => state.subscription);

  return (
    <Route
      {...rest}
      render={props =>
        isAfter(subscription.data.expiry, Date.now()) ? (
          <Component {...props} />
        ) : (
          <Plans />
        )
      }
    />
  );
};

export default SubGate;
