import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import Loader from "react-loader-spinner";
import CenterBox from "./CenterBox";

const PageLoader: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <CenterBox>
      <Loader type="Oval" color={theme.orange} height={100} width={100} />
    </CenterBox>
  );
};

export default PageLoader;
