import React from "react";
import styled from "styled-components";

const SeparatorBox = styled.div`
  margin-bottom: 5.8rem;

  @media screen and (max-width: 992px) {
    margin-bottom: 3rem;
  }

  hr {
    border: none;
    background: ${({ theme }) => theme.orange};
    height: 0.5rem;
    width: 7.9rem;
    margin-top: 4rem;

    @media screen and (max-width: 992px) {
      margin-top: 3rem;
      width: 6.5rem;
    }
  }
`;
const Separator: React.FC = () => (
  <SeparatorBox>
    <hr />
  </SeparatorBox>
);

export default Separator;
