import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PageContainer from "../PageContainer";

const Nav = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 4.9rem;
  color: ${({ theme }) => theme.black};
  font-size: 1.4rem;
  line-height: 1.7rem;

  @media screen and (max-width: 992px) {
    text-align: center;
    display: flex;
    justify-content: center;
  }

  li {
    display: inline-block;
    padding: 0 1.7rem;

    @media screen and (max-width: 992px) {
      padding: 0 2rem;

      &:first-child {
        padding: 0 1.7rem 0 0;
      }

      &:last-child {
        padding: 0 1.7rem 0 1.7rem;
      }
    }

    &:last-child {
      padding-right: 0;
    }

    &:not(:first-child) {
      border-left: 1px solid ${({ theme }) => theme.black};
    }
  }

  a {
    color: ${({ theme }) => theme.black};
    text-decoration: none;
  }

  svg {
    width: 14px;
    height: 14px;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
`;

const Div = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.border};
`;

const SecondaryNav: React.FC = () => (
  <Div>
    <PageContainer>
      <Nav>
        <ul>
          <li>
            <a href="http://asauk.net/about-us/contact/">Contact Us</a>
          </li>
          <li>
            <Link to="/login">Members Login</Link>
          </li>
        </ul>
      </Nav>
    </PageContainer>
  </Div>
);

export default SecondaryNav;
