import React, { useState, useEffect } from "react";
import { useGetSubscribers } from "./hooks";
import Subscribers from "./Subscribers";

const AllSubscribers: React.FC = () => {
  const [controls, setControls] = useState<{
    searchTerm: string;
    filter: string;
    page: number;
    page_size: number;
  }>({ searchTerm: "", filter: "", page: 1, page_size: 10 });

  const [subscribers, getSubscribers] = useGetSubscribers();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getSubscribers(
      controls.page,
      controls.page_size,
      controls.searchTerm,
      controls.filter
    ).finally(() => {
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controls]);

  const safeSetFilter = (filter: {
    searchTerm?: string;
    filter?: string;
    page?: number;
    page_size?: number;
  }) => {
    setControls({
      ...controls,
      ...filter
    });
  };

  return (
    <Subscribers
      subscribers={subscribers}
      setFilter={safeSetFilter}
      loading={isLoading}
      controls={controls}
    />
  );
};

export default AllSubscribers;
