import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  GET_SUBSCRIBERS,
  CREATE_SUBSCRIBER,
  GET_SUBSCRIBER,
  DEACTIVATE_SUBSCRIBER,
  ACTIVATE_SUBSCRIBER,
} from "../../../../redux/constants";
import { adminRequest } from "../../../../utils/request";
import keysToSnakeCase from "../../../../utils/keysToSnakeCase";

export const useGetSubscribers = () => {
  const dispatch = useDispatch();
  const subscribers: any = useSelector((state: any) => state.subscribers);

  const getSubscribers = async (
    page = 1,
    page_size = 10,
    searchTerm = "",
    filter = ""
  ) => {
    try {
      const { data } = await adminRequest.get(
        `/subscribers?search_term=${searchTerm}&filter=${filter}&page=${page}&page_size=${page_size}`
      );

      dispatch({
        type: GET_SUBSCRIBERS,
        payload: data.subscribers,
      });
    } catch (err) {
      if (err.response && err.response.status >= 400) {
        toast.error(err.response.data.error);
      } else {
        toast.error("An error occurred");
      }
    }
  };

  return [subscribers, getSubscribers] as const;
};

export const useCreateSubscriber = () => {
  const dispatch = useDispatch();
  const protocol = window.location.protocol;
  const returnUrl = `${protocol}//${window.location.host}/reset-password`;

  const createSubscriber = async (
    payload: { [key: string]: string },
    callback: Function
  ) => {
    try {
      const { data } = await adminRequest.post(
        `/subscribers`,
        keysToSnakeCase({
          ...payload,
          returnUrl,
        })
      );

      dispatch({
        type: CREATE_SUBSCRIBER,
        payload: data.subscribers,
      });

      toast.success("Member created!");

      callback();
    } catch (err) {
      if (err.response && err.response.status >= 400) {
        toast.error(err.response.data.error);
        return;
      }

      toast.error("An error occurred.");
    }
  };

  return createSubscriber;
};

export const useGetSubscriber = () => {
  const dispatch = useDispatch();
  const subscriber: any = useSelector((state: any) => state.subscriber);

  const getSubscriber = async (id: number) => {
    try {
      const { data } = await adminRequest.get(`/subscribers/${id}`);

      dispatch({
        type: GET_SUBSCRIBER,
        payload: data.subscriber,
      });
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return [subscriber, getSubscriber] as const;
};

export const useDeactivateSubscriber = () => {
  const dispatch = useDispatch();

  const deactivateSubscriber = async (id: number, callback: Function) => {
    try {
      const { data } = await adminRequest.put(`/subscribers/${id}`);

      dispatch({
        type: DEACTIVATE_SUBSCRIBER,
        payload: data.subscriber,
      });

      callback();

      toast.success("Member deactivated");
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return deactivateSubscriber;
};

export const useActivateSubscriber = () => {
  const dispatch = useDispatch();

  const activateSubscriber = async (id: number, callback: Function) => {
    try {
      const { data } = await adminRequest.post(`/subscribers/${id}`);

      dispatch({
        type: ACTIVATE_SUBSCRIBER,
        payload: data.subscriber,
      });

      callback();

      toast.success("Member activated!");
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return activateSubscriber;
};

export const useDeleteSubscriber = () => {
  const deleteSubscriber = async (id: number, callback: Function) => {
    try {
      await adminRequest.delete(`/subscribers/${id}`);

      callback();
    } catch (err) {
      if (err.response.status >= 400) {
        toast.error(err.response.data.error);
      }
    }
  };

  return deleteSubscriber;
};
