import React, { Component, Fragment } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";
import { CreditCardContainer } from "./styles";
import { Button, Spacer } from "../shared";
import supportPaymentOptions from "../../svgs/supported-cards.svg";
import Context from "./Context";
import donate from "../../redux/actions/donate";

class CheckoutForm extends Component<any> {
  state = {
    loading: false
  };

  submit = async (state: {
    setState: Function;
    firstName: string;
    lastName: string;
    source: string;
  }) => {
    try {
      this.setState({ loading: true });

      const name = `${state.firstName} ${state.lastName}`;
      const { token } = await this.props.stripe.createToken({ name });
      const { setState, ...donationData } = state;

      setState({
        ...donationData,
        source: token.id
      });

      donationData["source"] = token.id;

      await this.props.donate(donationData, this.props.history.push);
    } catch (err) {
      this.setState({
        loading: false
      });
    }
  };

  render() {
    return (
      <Context.Consumer>
        {state => (
          <Fragment>
            <CreditCardContainer>
              <CardElement
                style={{
                  base: {
                    fontSize: "14px",
                    "::placeholder": {
                      color: "#bdbdbd"
                    }
                  }
                }}
              />
            </CreditCardContainer>

            <Spacer height={3.1} />

            <Button
              type="button"
              large
              onClick={() => this.submit(state)}
              disabled={this.state.loading}
            >
              {this.state.loading ? (
                <Loader type="Oval" color="#ffffff" height={20} width={20} />
              ) : (
                "Donate"
              )}
            </Button>

            <Spacer height={5.7} />

            <img src={supportPaymentOptions} alt="Support payment options" />
          </Fragment>
        )}
      </Context.Consumer>
    );
  }
}

const TransformedCheckoutForm = injectStripe(withRouter(CheckoutForm));

export default connect(null, {
  donate
})(TransformedCheckoutForm);
